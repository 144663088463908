
import React, { useState } from "react";
import { Wrapper, ImageDataContainer, Header, Content, RecomendedSize, SelectImageContainer, UploadStockImageBtn, AiImageUploadBtn, StockImageContainer, BackContainer, SearchbarContainer, SearchButton, ImgWrapper } from "./index.styled"
import Masonry from "react-responsive-masonry"
import DummyImage from "../../../../../../../../../../media/Dashboard/photoIcon.svg"
import UploadImg from "../../../../../../../../../../media/Dashboard/UploadLogo.svg"
import AiImage from "../../../../../../../../../../media/Dashboard/stars.svg"
import LeftArrow from "../../../../../../../../../../media/Dashboard/backArrowGreyIcon.svg"
import SearchIconAdGen from "../../../../../../../../../../media/Dashboard/SearchIconAdGen.svg"
import SearchIconWhite from "../../../../../../../../../../media/Dashboard/search-white.svg"

const Images = () => {
    const [otherImagesOpen, setOtherImagesOpen] = useState(null)

    const [imageData, setImageData] = useState([
        { id: 1, name: "Image 1", url: "", recomendedSize: "300 W x 250 H" },
        { id: 2, name: "Image 2", url: "", recomendedSize: "500 W x 480 H" },
        { id: 3, name: "Image 3", url: "", recomendedSize: "500 W x 480 H" }
    ]);

    const onImageChange = (e, image) => {
        const file = e.target.files[0];
        const fileURL = URL.createObjectURL(file);

        setImageData(prevImageData =>
            prevImageData.map(item =>
                item.id === image.id ? { ...item, url: fileURL } : item
            )
        );
    };

    let tempStockImages = [
        "https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxfHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwyfHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1509631179647-0177331693ae?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwzfHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1484327973588-c31f829103fe?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw0fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1506152983158-b4a74a01c721?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw1fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1571513800374-df1bbe650e56?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw2fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1504198458649-3128b932f49e?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw3fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1492707892479-7bc8d5a4ee93?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw4fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1495385794356-15371f348c31?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw5fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1558769132-cb1aea458c5e?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxMHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1485231183945-fffde7cc051e?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxMXx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1529139574466-a303027c1d8b?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxMnx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1544957992-20514f595d6f?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxM3x8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1485518882345-15568b007407?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxNHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1485125639709-a60c3a500bf1?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxNXx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1519748771451-a94c596fad67?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxNnx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1519415943484-9fa1873496d4?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxN3x8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1603189343302-e603f7add05a?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxOHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1472417583565-62e7bdeda490?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxOXx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
        "https://images.unsplash.com/photo-1463100099107-aa0980c362e6?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwyMHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3"
    ]

    return <Wrapper>
        {
            otherImagesOpen ? <StockImageContainer>
                <h2>Image</h2>
                <BackContainer>
                    <img src={LeftArrow} onClick={()=>setOtherImagesOpen(null)}/><p>{(otherImagesOpen == "stock-image") ? "Stock images" : "AI image generation"}</p>
                </BackContainer>
                <SearchbarContainer>
                    <div><img src={SearchIconAdGen} /><input placeholder="Search Images" /></div><SearchButton><img src={SearchIconWhite} /></SearchButton>
                </SearchbarContainer>
                <ImgWrapper>
                    <Masonry gutter={'12px'}>
                        {
                            tempStockImages.map((item) => (
                                <img
                                    onClick={() => onImageChange()}
                                    src={item} />
                            ))
                        }
                    </Masonry>
                </ImgWrapper>
            </StockImageContainer>
                :
                imageData.map((image) => (
                    <ImageDataContainer key={image.id}>
                        <Header>
                            <label>{image.name}</label>
                        </Header>
                        <Content>
                            {image.url ? (
                                <img src={image.url} alt={image.name} />
                            ) : <img src={DummyImage} />}
                            <RecomendedSize>
                                <p>Recomended size: </p>
                                <p>{image.recomendedSize}</p>
                            </RecomendedSize>
                            <input
                                type="file"
                                accept="image/*"
                                id="upload"
                                onChange={(e) => onImageChange(e, image)}
                                hidden
                            />
                            {/* <label for="upload">Change</label> */}
                        </Content>
                        <SelectImageContainer>
                            <label for="upload"><img src={UploadImg} />Upload</label>
                            <UploadStockImageBtn onClick={()=>setOtherImagesOpen("stock-image")}>
                                <img src={DummyImage} />Stock images
                            </UploadStockImageBtn>
                            <AiImageUploadBtn onClick={()=>setOtherImagesOpen("ai-image")}>
                                <img src={AiImage} />AI
                            </AiImageUploadBtn>
                        </SelectImageContainer>
                    </ImageDataContainer>
                ))}
    </Wrapper>
}

export default Images