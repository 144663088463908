import styled from "styled-components";

export const PopupBodyContainer = styled.div`
    width: 100%;
    height: 17vh;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000001a;
  border-radius: 8px;
  padding: 4px;
  z-index: 1;
  top: 44px;
  /* right: 0; */
  /* top: 52px; */
  /* left: ${(props) => props.left + "px"};
  top: ${(props) => props.top + "px"}; */
`;

export const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const CountrySearchBar = styled.input`
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  /* border: 1px solid #ECECEC; */
  /* border-radius: 8px; */
  height: 100%;
  outline: none;
  padding: 6px 8px;
  color: rgb(46, 46, 46);
  font-size: 14px;
  font-weight: 300;
  width: 100% !important;
  /* margin-bottom: 10px; */
  outline: none;
  border: none;
`;

export const ClearSection = styled.div`
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 6px 12px;
  color: #2e2e2e;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: #ececec 0% 0% no-repeat padding-box;
  }
  &:active {
    background: #e2e2e2 0% 0% no-repeat padding-box;
  }
`;

export const CountriesListContainer = styled.div`
  /* height: 320px; */
  height: 100%;
  padding-top: 8px;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
    box-shadow: inset 0 0 6px rgb(0 0 0 / 11%);
    background-color: #f5f5f570;
    border-radius: 5px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00000040;
    border-radius: 5px;
  }
`;

export const CheckboxGroup = styled.div`
  > label {
    margin-left: 8px;
    cursor: pointer;
  }
  .region-name {
    margin-bottom: 8px;
  }
  margin-bottom: 8px;
`;

export const CheckBoxContainer = styled.div`
  background: ${(props) => (props.checked ? "#DCEEFF" : "white")};
  /* border: 1px solid #F2F2F2; */
  border-radius: 8px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;

  label {
    cursor: pointer;
    display: flex;
  }
  .ant-checkbox-wrapper-checked {
    background: #eef7ff 0% 0% no-repeat padding-box;
    color: #12558d;
  }
  p {
    text-align: left;
  }
  :hover {
    background-color: #f6f6f6;
  }
`;

export const Checkbox = styled.input`
  margin-right: 8px;
  cursor: pointer;
`;

export const CountriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  gap: 8px;
  /* margin-top: 14px; */
  /* margin-bottom: 8px; */
`;

export const Country = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  /* height: 24px; */
  cursor: pointer;
  :hover {
    background: #f9fafb;
  }
  padding: 0 8px;
`;

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

export const Flag = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 50%;
`;

export const CountryName = styled.div`
  color: rgba(28, 28, 28, 0.9);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const BodyFooter = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  height: 32px;
`;

export const CancelButton = styled.button`
  outline: none;
  color: #2e2e2e;
  border-radius: 8px;
  padding: 6px 12px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  &:hover {
    background: #f2f2f2 0% 0% no-repeat padding-box;
  }
  &:active {
    background: #ececec 0% 0% no-repeat padding-box;
  }
  &:disabled {
    /* bac */
  }
`;

export const ApplyButton = styled.button`
  outline: none;
  background: #006ecc 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
  border: none;
  margin-left: 8px;
  font-size: 14px;
  &:hover {
    background: #0059a5 0% 0% no-repeat padding-box;
  }
  &:active {
    background: #004a89 0% 0% no-repeat padding-box;
  }
  &:disabled {
    background: #d4d4d4 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
`;

export const DropdownContainer = styled.div`
  border-radius: 8px;

  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background: rgba(255, 255, 255, 0.6);
  height: 44px;
  display: flex;

  position: relative;
`;

export const DropdownButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 0px 8px;
  span {
    color: rgba(28, 28, 28, 0.5);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const DropdownOptions = styled.div`
  position: absolute;
  top: 34px;
  z-index: 5;
  width: 100%;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 4px;
`;

export const DropDownImage = styled.div`
  img {
    height: 11px;
    margin-left: 8px;
  }
`;

export const CrossImageContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border-radius: 10px;
  img {
    height: 12px;
  }
  &:hover {
    background: #a8d0ef;
  }
`;
