import { useState } from "react";
import { appClient } from "../../../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAdGenerator } from "../context/adGeneration";
import { useAuth } from "../../../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useTextAdGenerator } from "../TextAd/context/textAdGeneration";
import { getSearchParams } from "../../../../utils/helper";

const getUserAdId = (data) => {
  const config = {
    url: `/new-text-ad/add-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getWebsiteMeta = (data) => {
  const config = {
    url: `/new-text-ad/get-processed-state`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const GenerateAd = (data) => {
  const config = {
    url: `/new-text-ad/add-ad-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getGenerate = (data) => {
  const config = {
    url: `/new-text-ad/get-generation-process`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getAdGroups = (data) => {
  const config = {
    url: `/new-text-ad/get-ad-groups`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getSingleAdGroups = (data) => {
  const config = {
    url: `/new-text-ad/get-single-group`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const adDetails = (data) => {
  const config = {
    url: `/new-text-ad/get-ad-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const updateAdDetails = (data) => {
  const config = {
    url: `/new-text-ad/update-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};

export const useTextAdGenInfo = () => {
  const navigate = useNavigate()
  const {
    adGeneratorState,
    adGeneratorDispatch,
  } = useAdGenerator();
  const location = useLocation();

  // const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator();
  // const [wesbiteMetaLoading, setWesbiteMetaLoading] = useState(false);

  const { name, description, productDetails, colors, images } = adGeneratorState.brandDetails;

  const { authState } = useAuth();

  const { mutate: getUserAdIdMutate, isLoading: isUserAdIdLoading } =
    useMutation(getUserAdId, {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_GENERATED_AD_ID",
          value: data.userAdId,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: getWebsiteMetaMutate, isLoading: isWebsiteMetaLoading } =
    useMutation(getWebsiteMeta, {
      onSuccess: (data, variables) => {
        // console.log(data, !data.processed);
        if (!data.processed) {
          setTimeout(() => {
            getWebsiteMetaMutate({
              userAdId: adGeneratorState.generatedAdId,
              projectId: authState.defaultProjectInfo.projectId,
            });
          }, 2000);
        } else {
          adGeneratorDispatch({
            type: "SET_WEBSITE_META_LOADING",
            value: false,
          });

          adGeneratorDispatch({
            type: "SET_KEYWORD_SUGGESTION",
            value: data.brandDetails.seedKeywords,
          });
          adGeneratorDispatch({
            type: "SET_OCCUPATION_ITEM",
            value: data.brandDetails.personas,
          });
          setTimeout(() => {
            if (!name) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                },
              });
            }
          }, 500);

          setTimeout(() => {
            if (!description) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutBrand
                    ? data.brandDetails.aboutBrand
                    : "",
                },
              });
            }
          }, 1000);

          setTimeout(() => {
            if (!productDetails) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutBrand
                    ? data.brandDetails.aboutBrand
                    : "",
                  productDetails: data.brandDetails.pageDetailSummary
                    ? data.brandDetails.pageDetailSummary
                    : "",
                },
              });
            }
          }, 1500);

          // adGeneratorDispatch({
          //   type: "SET_BRAND_DETAILS", value: {
          //     ...adGeneratorState.brandDetails,
          //     description: data.brandDetails.aboutBrand ? data.brandDetails.aboutBrand : "",
          //     productDetails: data.brandDetails.pageDetailSummary,
          //   }
          // })
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: getGenerateProcess, isLoading: isGenerateProcessLoading } =
    useMutation(getGenerate, {
      onSuccess: (data) => {
        const allCompleted = data.processed.every((task) => task.isCompleted);
        const searchParamFromState = getSearchParams(location.search);

        const { id } = searchParamFromState;
        if (!allCompleted) {
          console.log("Some tasks are not completed. Checking again...");
          setTimeout(() => {
            getGenerateProcess({
              userAdId: id,
            });
          }, 3000);
        } else {
          // adGeneratorDispatch({
          //   type: "SET_GENERATION_PROCESS",
          //   value: true,
          // });
          getAdGroupsData({
            userAdId: id,
          });
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: generateAdMutate, isLoading: isGenerateAdLoading } =
    useMutation(GenerateAd, {
      onSuccess: (data) => {
        if (data.userAdId) {
          navigate(`/adgenerator-v3/text-ad/result?id=${data.userAdId}`)
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const { mutate: getAdGroupsData, isLoading: isAdGroupsDataLoading } =
    useMutation(getAdGroups, {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_GENERATION_PROCESS",
          value: data,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    });

  const {
    mutate: getSingleAdGroupsData,
    isLoading: isSingleAdGroupsDataLoading,
  } = useMutation(getSingleAdGroups, {
    onSuccess: (data) => {
      adGeneratorDispatch({
        type: "SET_ADGROUP_ITEM",
        value: data,
      });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => { },
  });

  const { mutate: getAdDetails, isLoading: isAdDetailsLoading } = useMutation(
    adDetails,
    {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_AD_DETAILS",
          value: data,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    }
  );
  const { mutate: getUpdateAd, isLoading: isUpdateAdLoading } = useMutation(
    updateAdDetails,
    {
      onSuccess: (data) => {
        navigate(`/adgenerator-v3/text-ad/result?id=${data.userAdId}`)
        adGeneratorDispatch({
          type: "SET_GENERATION_PROCESS",
          value: null,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => { },
    }
  );
  return {
    getUserAdIdMutate,
    isUserAdIdLoading,

    getWebsiteMetaMutate,
    isWebsiteMetaLoading,

    generateAdMutate,
    isGenerateAdLoading,
    getGenerateProcess,
    isGenerateProcessLoading,
    getAdGroupsData,
    isAdGroupsDataLoading,
    getSingleAdGroupsData,
    isSingleAdGroupsDataLoading,
    getAdDetails,
    isAdDetailsLoading,
    getUpdateAd,
    isUpdateAdLoading
  }
};
