import React, { useEffect, useState } from "react";
import {
  InnerContentWrap,
  InnerContentContainer,
  TargetPersonaWrap,
  TargetPersona,
  TargetDescrip,
  DestinationUrlWrap,
  DestinationUrl,
  HeadLineWrap,
  InputWrap,
  InputContainer,
  HeadLine,
  DescriptionWrap,
  KeywordContainer,
  KeywordWrap,
  KeywordDiv,
  AddHeadLine,
} from "./index.styled";
import { useTextAdGenerator } from "../../../../context/textAdGeneration";
import upArrow from "../../../../../../../../media/Dashboard/uparrow.png";
import globeIcon from "../../../../../../../../media/Dashboard/globe-black.svg";
import deleteIcon from "../../../../../../../../media/Dashboard/DeleteIcon.svg";
import cancelIcon from "../../../../../../../../media/Dashboard/closeIcon.svg";
import { useForm } from "react-hook-form";
import { useAdGenerator } from "../../../../../context/adGeneration";
import { formatNumber } from "../../../../../../../../utils/formatTotal";

const AdGroupInnerContent = () => {
  const { textAdGeneratorState, textAdGeneratorDispatch } =
    useTextAdGenerator();
  const {
    adGeneratorState: { adGroupItem },
    adGeneratorDispatch,
  } = useAdGenerator();

  const { register, handleSubmit, setValue } = useForm();
  const [destinUrl, setDestinUrl] = useState(true);
  const [headingState, setHeadingState] = useState(true);
  const [descriptionState, setDescriptionState] = useState(true);
  const [keywordState, setKeywordState] = useState(true);

  const onHeadingInputChange = (e, idx) => {
    const updatedTitleList = [...adGroupItem.adGroupRow?.titles];

    updatedTitleList[idx] = e.target.value;

    const updatedAdGroupItem = {
      ...adGroupItem,
      adGroupRow: {
        ...adGroupItem.adGroupRow,
        titles: updatedTitleList,
      },
    };
    adGeneratorDispatch({
      type: "SET_ADGROUP_ITEM",
      value: updatedAdGroupItem,
    });
  };

  const onDescripInputChange = (e, desIndex) => {
    const updatedDescripList = [...adGroupItem?.adGroupRow?.descriptions];
    updatedDescripList[desIndex] = e.target.value;

    const updatedAdGroupItem = {
      ...adGroupItem,
      adGroupRow: {
        ...adGroupItem.adGroupRow,
        descriptions: updatedDescripList,
      },
    };

    adGeneratorDispatch({
      type: "SET_ADGROUP_ITEM",
      value: updatedAdGroupItem,
    });
  };
  
  const onKeywordChange = (e) => {
    if (e.target.value.trim() != "" && e.key === "Enter") {
      const keywordValue = e.target.value.trim();
      const newKeywordObject = {
        keyword: keywordValue,
        averageCpc: null, 
        searchVolume: null, 
      };
      const updatedKeywordList = [...adGroupItem?.adGroupRow.keywordsArray];
      updatedKeywordList.push(newKeywordObject);
      const updatedAdGroupItem = {
        ...adGroupItem,
        adGroupRow: {
          ...adGroupItem.adGroupRow,
          keywordsArray: updatedKeywordList,
        },
      };
      adGeneratorDispatch({
        type: "SET_ADGROUP_ITEM",
        value: updatedAdGroupItem,
      });
      e.target.value = "";
    }
  };
  const addEmptyHeading = () => {
    const emptyTitleList = [...adGroupItem.adGroupRow?.titles];
    const AdEmptyGroupItem = {
      ...adGroupItem,
      adGroupRow: {
        ...adGroupItem.adGroupRow,
        titles: [...emptyTitleList, ""],
      },
    };
    adGeneratorDispatch({
      type: "SET_ADGROUP_ITEM",
      value: AdEmptyGroupItem,
    });
  };
  // console.log(adGroupItem, "adGroupItem");

  const addEmptyDecription = () => {
    const emptyDecripList = [...adGroupItem?.adGroupRow?.descriptions];

    const AdEmptyGroupItem = {
      ...adGroupItem,
      adGroupRow: {
        ...adGroupItem.adGroupRow,
        descriptions: [...emptyDecripList, ""],
      },
    };

    adGeneratorDispatch({
      type: "SET_ADGROUP_ITEM",
      value: AdEmptyGroupItem,
    });
  };
  const deleteHeading = (idx) => {
    const deleteHeadingList = [...adGroupItem.adGroupRow?.titles];

    const removeHeadItem = {
      ...adGroupItem,
      adGroupRow: {
        ...adGroupItem.adGroupRow,
        titles: deleteHeadingList.filter((d, i) => i !== idx),
      },
    };

    adGeneratorDispatch({
      type: "SET_ADGROUP_ITEM",
      value: removeHeadItem,
    });
  };

  const deleteDescrip = (idx) => {
    const deleteDescripList = [...adGroupItem?.adGroupRow?.descriptions];

    const removeDescripItem = {
      ...adGroupItem,
      adGroupRow: {
        ...adGroupItem.adGroupRow,
        descriptions: deleteDescripList.filter((d, i) => i !== idx),
      },
    };

    adGeneratorDispatch({
      type: "SET_ADGROUP_ITEM",
      value: removeDescripItem,
    });
  };

  const removeKeyword = (index) => {
    const updatedKeywordList = adGroupItem?.adGroupRow.keywordsArray.filter(
      (_, i) => i !== index
    );

    const updatedAdGroupItem = {
      ...adGroupItem,
      adGroupRow: {
        ...adGroupItem.adGroupRow,
        keywordsArray: updatedKeywordList,
      },
    };

    adGeneratorDispatch({
      type: "SET_ADGROUP_ITEM",
      value: updatedAdGroupItem,
    });
  };

  useEffect(() => {
    adGroupItem?.headlineText?.length == 0 &&
      adGeneratorDispatch({
        type: "SET_ADGROUP_ITEM",
        value: {
          ...adGroupItem,
          headlineText: [...[...adGroupItem.headlineText], "", "", "", ""],
        },
      });
    adGroupItem?.descriptionList?.length == 0 &&
      adGeneratorDispatch({
        type: "SET_ADGROUP_ITEM",
        value: {
          ...adGroupItem,
          descriptionList: [...[...adGroupItem.descriptionList], "", "", ""],
        },
      });
  }, []);

  const renderHeadLine = () => {
    // const isHeadingDisabled = adGroupItem?.headlineText.length <= 4;

    return (
      <HeadLineWrap>
        <TargetPersona props={headingState}>
          <p>
            Headline<span>(14/15)</span>
          </p>
          <img src={upArrow} onClick={() => setHeadingState(!headingState)} />
        </TargetPersona>
        {headingState && (
          <div>
            {adGroupItem &&
              adGroupItem?.adGroupRow?.titles.map((headline, idx) => {
                return (
                  <>
                    <HeadLine>Headline {idx + 1}</HeadLine>
                    <InputWrap>
                      <InputContainer>
                        <form>
                          <input
                            value={headline}
                            onChange={(e) => onHeadingInputChange(e, idx)}
                            maxLength={30}
                          />
                          <p>{`${headline.length}/30`}</p>
                        </form>
                      </InputContainer>
                      <img
                        src={deleteIcon}
                        alt="delete"
                        onClick={() => deleteHeading(idx)}
                      />
                    </InputWrap>
                  </>
                );
              })}
            <AddHeadLine onClick={addEmptyHeading}>+ add headline</AddHeadLine>
          </div>
        )}
      </HeadLineWrap>
    );
  };
  const renderDescription = () => {
    return (
      <DescriptionWrap>
        <TargetPersona props={descriptionState}>
          <p>
            Description<span>(3/4)</span>
          </p>
          <img
            src={upArrow}
            onClick={() => setDescriptionState(!descriptionState)}
          />
        </TargetPersona>
        {descriptionState && (
          <>
            {adGroupItem?.adGroupRow?.descriptions?.map((descrip, desIndex) => {
              return (
                <>
                  <HeadLine>Description {desIndex + 1}</HeadLine>
                  <InputWrap>
                    <InputContainer id="decsripTextArea">
                      <form>
                        <textarea
                          value={descrip}
                          onChange={(e) => onDescripInputChange(e, desIndex)}
                        />
                        <p>{`${descrip.length}/30`}</p>
                      </form>
                    </InputContainer>
                    <img
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => deleteDescrip(desIndex)}
                    />
                  </InputWrap>
                </>
              );
            })}
            <AddHeadLine onClick={addEmptyDecription}>
              + add description
            </AddHeadLine>
          </>
        )}
      </DescriptionWrap>
    );
  };

  const renderKeyword = () => {
    return (
      <DescriptionWrap>
        <TargetPersona props={keywordState}>
          <p>
            Keywords<span>(2/20)</span>
          </p>
          <img src={upArrow} onClick={() => setKeywordState(!keywordState)} />
        </TargetPersona>
        {keywordState && (
          <KeywordWrap>
            <input
              {...register(`keywordField`)}
              placeholder="Type or add keywords from the left panel"
              onKeyDown={(e) => onKeywordChange(e)}
            />
            <KeywordContainer>
              {adGroupItem?.adGroupRow?.keywordsArray?.map((item, index) => (
                <KeywordDiv colorProp={index}>
                  {`${item.keyword} (${formatNumber(item.searchVolume)}Vol)`}
                  <img src={cancelIcon} onClick={() => removeKeyword(index)} />
                </KeywordDiv>
              ))}
            </KeywordContainer>
            <p>0/30</p>
          </KeywordWrap>
        )}
      </DescriptionWrap>
    );
  };
  return (
    <InnerContentWrap>
      <InnerContentContainer>
        <TargetPersonaWrap>
          <TargetPersona>Target persona</TargetPersona>
          <TargetDescrip>{adGroupItem?.title}</TargetDescrip>
        </TargetPersonaWrap>
        <DestinationUrlWrap>
          <TargetPersona props={destinUrl}>
            <p>Destination URL</p>
            <img src={upArrow} onClick={() => setDestinUrl(!destinUrl)} />
          </TargetPersona>
          {destinUrl && (
            <DestinationUrl>
              <img src={globeIcon} />
              {adGroupItem?.domain}
            </DestinationUrl>
          )}
        </DestinationUrlWrap>
        {renderHeadLine()}
        {renderDescription()}
        {renderKeyword()}
      </InnerContentContainer>
    </InnerContentWrap>
  );
};

export default AdGroupInnerContent;
