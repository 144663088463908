import React from "react";
import {
  AccountDataContainer,
  AccountDataDiv,
  AccountDataWrap,
  IncreaseWrap,
  SpendValue,
} from "./index.styled";
import adResultData from "../../../../static";
import increaseArrow from "../../../../../../../../media/Dashboard/arrow-up-green.svg";
import AccountDetailsGraph from "./AccountDetailsGraph";
const AccountDataDetails = () => {
  const { metricDetails } = adResultData;
  return (
    <AccountDataWrap>
      <AccountDataContainer>
        {metricDetails.map((item) => (
          <AccountDataDiv>
            <p>{item.title}</p>
            <SpendValue>{item.spend}</SpendValue>
            <IncreaseWrap>
              <img src={increaseArrow} />
              <span>14%</span>
              vs Last month
            </IncreaseWrap>
          </AccountDataDiv>
        ))}
      </AccountDataContainer>
      <AccountDetailsGraph />
    </AccountDataWrap>
  );
};

export default AccountDataDetails;
