const statics = {
    steps: [
        {
            header: `What do you want to <span>achieve</span> with the <span>ad?</span>`,
            options: [
                "Get more website visitors",
                "Boost Online Sales",
                "Promote Your Store",
                "Raise Brand Awareness",
                "Increase Social Media Followers/Boost Post Engagement",
                "Promote Special Offers",
                "Get More App Downloads",
                "Launch New Products or Services",
                "Promote Events"
            ]
        },
        {
            header: "Describe more about your adgoal",
            subHeader: "Do you want to describe more?",
            isOptional: true,
            description: "Couponswala group provides services in the e-commerce sector to shoppers across India. Our main aim is to provide high-quality services which will help our shoppers/customers to save on every purchase you make. We have strong partnerships with great businesses which form our core to providing the best experiences to our valued users whether they are purchasing groceries, electronics, clothing, etc."
        },
        {
            header: "Enter the destination URL of the ad"
        },
        {
            header: "Brand details",
            info: "These fields are auto filled using AI",
            brandName: "Couponswala",
            brandDescription: "Couponswala group provides services in the e-commerce sector to shoppers across India. Our main aim is to provide high-quality services which will help our shoppers/customers to save on every purchase you make. We have strong partnerships with great businesses which form our core to providing the best experiences to our valued users whether they are purchasing groceries, electronics, clothing, etc. ",
            productDetails: "Couponswala group provides services in the e-commerce sector to shoppers across India. Our main aim is to provide high-quality services which will help our shoppers/customers to save on every purchase you make. We have strong partnerships with great businesses which form our core to providing the best experiences to our valued users whether they are purchasing groceries, electronics, clothing, etc. ",
            colors: ["#DF0035", "#E8E8E8", "#DFDFDF"]
        },
        {
            header: "Target audience",
            info: "These fields are auto filled using AI",
            genders: ["Male", "Female", "Both"],
            ageGroups: ["18-24", "25-34", "35-44", "44-54", "55-64", "65+"],
            occupations: [
                "The IT professional",
                "The Tech-Savvy Teenager",
                "The Busy Professional",
                "The Retired Traveler",
                "The Eco-Conscious Parent",
                "The College Student",
                "The Fitness Enthusiast",
                "The DIY Homeowner",
                "The Aspiring Artist",
                "The First-Time Parent",
                "The Small Business Owner",
                "The Freelance Designer",
                "The Outdoor Adventurer",
                "The Social Media Influencer",
                "The Work-From-Home Parent",
                "The Startup Founder",
                "The Health-Conscious Senior",
                "The Urban Commuter",
                "The Young Entrepreneur",
                "The Digital Nomad",
                "The Gamer"
            ]
        },
        {
            image: {
                header: "Ad type",
                types: [
                    {
                        type: "Social media ad",
                        description: "Social media ads are typically one size, usually with a 1:1 aspect ratio.",
                        platforms: ["meta", "linkedin", "tiktok", "twitter", "reddit"],
                        programmaticName: "socialMedia"
                    },
                    {
                        type: "Display ad",
                        description: "Display ads come in various sizes  for flexible web placements.",
                        platforms: ["google", "bing"],
                        programmaticName: "display"
                    }
                ]
            },
            text: {
                header: "Seed keywords",
                recommendingKeywords: [
                    "display", "apple", "iphone", "macbook pro", "display", "apple", "iphone"
                ]
            }
        }
    ]
}

export default statics