import React from "react";
import { useReducer, createContext, useContext } from "react";

import produce from "immer";

const initialState = {
  connectedAdAccount: null,
  selectAdAccount: false,
  manageAccount: false,
  isIntegrationId: null,
  listAllAdAccounts: [],
};

const adManagementTypeReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_CONNECTED_DATA":
      draft.connectedAdAccount = action.value;
      break;
    case "SET_SELECT_AD_ACCOUNT":
      draft.selectAdAccount = action.value;
      break;
    case "SET_MANAGE_ACCOUNT":
      draft.manageAccount = action.value;
      break;
    case "SET_INTEGRATION_ID":
      draft.isIntegrationId = action.value;
      break;
    case "SET_LIST_AD_ACCOUNTS":
      draft.listAllAdAccounts = action.value;
      break;

    default:
      break;
  }
});

const AdManagementReducer = () => {
  return useReducer(adManagementTypeReducer, initialState);
};

const AdManagementNewContext = createContext();

export const AdManagementNewProvider = ({ children }) => {
  const [state, dispatch] = AdManagementReducer();
  const value = {
    newAdManagementState: state,
    newAdManagementDispatch: dispatch,
  };

  return (
    <AdManagementNewContext.Provider value={value}>
      {children}
    </AdManagementNewContext.Provider>
  );
};

export const useAdIntegration = () => {
  return useContext(AdManagementNewContext);
};
