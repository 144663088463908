import React, { useState, useEffect } from "react";
import { Wrapper, ColorDataContainer, Header, ColorListContainer, ColorContainerWrapper, ColorContainer } from "./index.styled"

const Color = () => {
    const [selectedColor, setSelectedColor] = useState({});
    const [colorData, setColorData] = useState([
        { id: 1, name: "Main color", colorsList: ["#23414E", "#0AB9F1", "#2A59E2", "#0D7183", "#FF7272", "#FF711E"] },
        { id: 2, name: "Text 1", colorsList: ["#1C1C1C", "#FFF"] },
        { id: 3, name: "Text 2", colorsList: ["#1C1C1C", "#FFF"] },
        { id: 4, name: "CTA", colorsList: ["#23414E", "#0AB9F1", "#2A59E2", "#0D7183", "#FF7272", "#FF711E"] }
    ]);

    const onColorChange = (color, name) => {
        const newObj = {}
        newObj[name] = color
        setSelectedColor({ ...selectedColor, ...newObj })
    };

    useEffect(() => {
        
    }, [])
    

    return <Wrapper>
        {colorData.map((element) => (
            <ColorDataContainer key={element.id}>
                <Header>
                    <label>{element.name}</label>
                </Header>
                <ColorListContainer>
                    {element.colorsList.map((c) => {
                        return <ColorContainerWrapper 
                            onClick={() => onColorChange(c, element.name)} 
                            isSelected={selectedColor[element.name] == c}
                        >
                            <ColorContainer style={{ background: c }} />
                        </ColorContainerWrapper>
                    })}
                </ColorListContainer>
            </ColorDataContainer>
        ))}
    </Wrapper>
}

export default Color