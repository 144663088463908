import styled from "styled-components";

export const Wrapper = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 20px; 
    h2{
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        color: #1C1C1C;
    } 
`;

export const SelectedPlatformDropdown = styled.div`
    border-radius: 6px;
    border: solid 1px #DADFE4;
    background: #FFF;
    height: 44px;
    width: 100%;
    position: relative;
`;

export const SelectedPlatform = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    cursor: pointer;
    
    div{
        display: flex;
        gap: 10px;
    }
    img{
        height: 18px;
    }
    .arrow{
        rotate: ${props => props.dropDownOpen ? "0deg": "180deg"};
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1C1C1C;
    }
`;

export const DropDown = styled.div`
    position: absolute;
    width: 100%;
    background: #FFF;
    top: 45px;
    border: solid 1px #DADFE4;
    border-radius: 5px;
    z-index: 1;
`;

export const DropDownOption = styled.div`
    height: 40px;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    cursor: pointer;
    border-bottom: solid 1px #dadfe4bb;
    font-size: 14px;
    font-weight: 400;
    img{
        height: 18px;
    }
`;

export const PreviewElement = styled.div`
    border-radius: 7px;
    border: 1px #E8E8E8;
    background: #ECECEC;
    height: 410px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0px;

`;

export const AudienceContainer = styled.div`
    margin: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1C1C1C;
    }
    p{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #1C1C1C;
    }
`;

export const BudgetContainer = styled.div`
    margin: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    label{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1C1C1C;
    }
    p{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #1C1C1C;
    }
    span{
        font-weight: 400;
    }
`;

export const PublishButton = styled.button`
    border-radius: 7px;
    background: #FF711E;
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
    cursor: pointer;
`;

export const PreviewAdCard = styled.div`
    background: #FFF;
    border-radius: 7px;
    .ad-image{
        width: 240px;
    }
`;

export const PreviewAdHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    img{
        width: 30px;
        border-radius: 50%;
    }

    .three-dots{
        width: 10px;
        rotate: 90deg;
        height: 10px;
    }
`;

export const HeaderTitleWrapper = styled.div`
    margin-left: 8px;
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    h3{
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
    }
    p{
        font-size: 7px;
        font-weight: 500;
        color: #727272;
        line-height: 8px;
        display: flex;
        img{
            height: 7px;
            width: 7px;
            margin-left: 3px;
        }
    }
`;

export const PreviewAdDescription = styled.p`
    font-size: 8px;
    font-weight: 300;
    color: #000;
    line-height: normal;
    width: 240px;
    padding: 0px 12px 8px;
`;