import React, { useEffect, useState } from "react";
import {
  AgeCount,
  CountryList,
  CountryName,
  CountryWrap,
  DropDownContainer,
  Dropdown,
  Gender,
  GenderType,
  LanguageDiv,
  LanguageWrap,
  PositionWrapper,
  TargetAudienceContainer,
  TargetAudienceWrap,
  TargetTitle,
} from "./index.styled";
import downArrow from "../../../../../../../../../../../../media/Dashboard/down-arrow-grey.svg";
import CountryDropDown from "./CountryDropDown/index";
import { useTextAdGenerator } from "../../../../../../../../context/textAdGeneration";
import cancelIcon from "../../../../../../../../../../../../media/Dashboard/closeIcon.svg";
import {useAdGenerator} from "../../../../../../../../../context/adGeneration"

const TargetAudience = () => {
  const [languageDrop, setLanguageDrop] = useState(false);
  const [occupationDrop, setOccupationDrop] = useState(false);

  const {
    textAdGeneratorState,
    textAdGeneratorDispatch,
  } = useTextAdGenerator();
  const { adGeneratorState: {isAdDetails}, adGeneratorDispatch } = useAdGenerator()

  const languageList = ["Hindi", "Tamil", "English"];
  const OccupationList = ["IT professional", "Software", "Marketer"];

  const renderLanguage = (langItem) => {
    const updatedLanguage = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        language: langItem,
      },
    };

    adGeneratorDispatch({
      type: "SET_AD_DETAILS",
      value: updatedLanguage,
    });
    setLanguageDrop(!languageDrop);
  };

  const renderOccupation = (occuItem) => {
    const updatedOccupation = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        occupation: occuItem,
      },
    };

    adGeneratorDispatch({
      type: "SET_AD_DETAILS",
      value: updatedOccupation,
    });
    setOccupationDrop(!occupationDrop);
  };

  const removeCountry = (country) => {
    const removeCount = isAdDetails?.adDetails?.locations.filter(
      (count) => count != null && count.code !== country.code
    );
    const updatedLocation = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        locations: removeCount,
      },
    };
    adGeneratorDispatch({
      type: "SET_AD_DETAILS",
      value: updatedLocation,
    });
  };
  const handleGenderClick = (genderItem) => {
    const updatedGender = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        gender: genderItem,
      },
    };
    adGeneratorDispatch({
      type: "SET_AD_DETAILS",
      value: updatedGender,
    });
  };

  const handleAgeClick = (e) => {
    const updatedAge = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        age: e.target.innerText,
      },
    };
    adGeneratorDispatch({
      type: "SET_AD_DETAILS",
      value: updatedAge,
    });
  };
  

  return (
    <TargetAudienceWrap>
      <TargetAudienceContainer>
        <TargetTitle>Gender</TargetTitle>
        <GenderType>
          <Gender
            isActive={isAdDetails?.adDetails?.gender === "male"}
            onClick={() => handleGenderClick("male")}
          >
            Male
          </Gender>
          <Gender
            isActive={isAdDetails?.adDetails?.gender === "Female"}
            onClick={() => handleGenderClick("female")}
          >
            Female
          </Gender>
          <Gender
            isActive={isAdDetails?.adDetails?.gender === "Others"}
            onClick={() => handleGenderClick("Others")}
          >
            Others
          </Gender>
        </GenderType>
      </TargetAudienceContainer>
      <TargetAudienceContainer>
        <TargetTitle>Age</TargetTitle>
        <GenderType>
          <AgeCount
            isActive={isAdDetails?.adDetails?.age === "18-24"}
            onClick={(e) => handleAgeClick(e)}
          >
            18-24
          </AgeCount>
          <AgeCount
            isActive={isAdDetails?.adDetails?.age === "25-34"}
            onClick={(e) => handleAgeClick(e)}
          >
            25-34
          </AgeCount>
          <AgeCount
            isActive={isAdDetails?.adDetails?.age === "35-44"}
            onClick={(e) => handleAgeClick(e)}
          >
            35-44
          </AgeCount>
          <AgeCount
            isActive={isAdDetails?.adDetails?.age === "44-54"}
            onClick={(e) => handleAgeClick(e)}
          >
            44-54
          </AgeCount>
          <AgeCount
            isActive={isAdDetails?.adDetails?.age === "55-64"}
            onClick={(e) => handleAgeClick(e)}
          >
            55-64
          </AgeCount>
          <AgeCount
            isActive={isAdDetails?.adDetails?.age === "65+"}
            onClick={(e) => handleAgeClick(e)}
          >
            65+
          </AgeCount>
        </GenderType>
      </TargetAudienceContainer>
      <TargetAudienceContainer>
        <TargetTitle>Language</TargetTitle>
        <PositionWrapper>
          <LanguageWrap onClick={() => setLanguageDrop(!languageDrop)}>
            <LanguageDiv>{isAdDetails?.adDetails?.language}</LanguageDiv>
            <img src={downArrow} />
          </LanguageWrap>
          {languageDrop && (
            <DropDownContainer>
              {languageList.map((item) => {
                return (
                  <Dropdown onClick={() => renderLanguage(item)}>
                    {item}
                  </Dropdown>
                );
              })}
            </DropDownContainer>
          )}
        </PositionWrapper>
      </TargetAudienceContainer>
      <TargetAudienceContainer>
        <TargetTitle>Occupation</TargetTitle>
        <PositionWrapper>
          <LanguageWrap onClick={() => setOccupationDrop(!occupationDrop)}>
            <LanguageDiv>{isAdDetails?.adDetails?.occupation}</LanguageDiv>
            <img src={downArrow} />
          </LanguageWrap>
          {occupationDrop && (
            <DropDownContainer>
              {OccupationList.map((item) => {
                return (
                  <Dropdown onClick={() => renderOccupation(item)}>
                    {item}
                  </Dropdown>
                );
              })}
            </DropDownContainer>
          )}
        </PositionWrapper>
      </TargetAudienceContainer>
      <TargetAudienceContainer>
        <TargetTitle>Country</TargetTitle>
        <CountryWrap>
          <CountryDropDown />
          <CountryList>
            {isAdDetails?.adDetails?.locations &&
              isAdDetails?.adDetails?.locations.map((item) => {
                return (
                  item != null && (
                    <CountryName>
                      <img src={item?.flags?.svg} />
                      {item?.name?.common}
                      <img
                        src={cancelIcon}
                        onClick={() => removeCountry(item)}
                      />
                    </CountryName>
                  )
                );
              })}
          </CountryList>
        </CountryWrap>
      </TargetAudienceContainer>
    </TargetAudienceWrap>
  );
};

export default TargetAudience;
