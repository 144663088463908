import React from "react";
import PreviewImage from "../../../../../../../../media/Dashboard/send-square.svg"
import CaptionImage from "../../../../../../../../media/Dashboard/text-block.svg"
import TextImage from "../../../../../../../../media/Dashboard/smallcaps.svg"
import ImageImage from "../../../../../../../../media/Dashboard/send-2.svg"
import AnimateImage from "../../../../../../../../media/Dashboard/video-square.svg"
import GalleryImage from "../../../../../../../../media/Dashboard/gallery.svg"
import ImageAdResultDummy from "../../../../../../../../media/Dashboard/ImageAdResultDummy.svg"


import { Wrapper, TemplateContainer, TemplateWrapper, EditContainer, NavigationComponent, NavElementContainer, Divider, EditComponent } from "./index.styled"
import { useImageAdGenerator } from "../../../../context/imageAdGeneration";
import Preview from "./components/preview";
import Caption from "./components/caption";
import Texts from "./components/texts";
import Images from "./components/image";
import Cta from "./components/cta";
import Color from "./components/color";
import Animate from "./components/Animate";

const EditResult = () => {

    const { imageAdGeneratorState, imageAdGeneratorDispatch } = useImageAdGenerator()
    const NavElementsData = [
        {
            name: "Preview",
            imgUrl: PreviewImage
        },
        {
            name: "",
            imgUrl: null
        },
        {
            name: "Caption",
            imgUrl: CaptionImage
        },
        {
            name: "Texts",
            imgUrl: TextImage
        },
        {
            name: "Image",
            imgUrl: ImageImage
        },
        {
            name: "CTA",
            imgUrl: AnimateImage
        },
        {
            name: "Color",
            imgUrl: PreviewImage
        },
        {
            name: "Animate",
            imgUrl: GalleryImage
        }
    ]

    const renderEditUi = () => {
        switch (imageAdGeneratorState.curentDetailsTab) {
            case "Preview":
                return <Preview />

            case "Caption":
                return <Caption />

            case "Texts":
                return <Texts />

            case "Image":
                return <Images />

            case "CTA":
                return <Cta />

            case "Color":
                return <Color />

            case "Animate":
                return <Animate />

            default:
                break;
        }
    }

    return <Wrapper>
        <TemplateContainer>
            <TemplateWrapper>
                <img src={ImageAdResultDummy} />
            </TemplateWrapper>
        </TemplateContainer>
        <EditContainer>
            <NavigationComponent>
                {
                    NavElementsData.map((ele) => {
                        if (ele.imgUrl) {
                            return <NavElementContainer
                                isActive={imageAdGeneratorState.curentDetailsTab == ele.name} onClick={() => {
                                    imageAdGeneratorDispatch({ type: "SET_CURRENT_DETAILS_TAB", value: ele.name })
                                }}
                            >
                                <img src={ele.imgUrl} />
                                <p>{ele.name}</p>
                            </NavElementContainer>
                        } else {
                            return <Divider />
                        }

                    })
                }
            </NavigationComponent>
            <EditComponent>
                {renderEditUi()}
            </EditComponent>
        </EditContainer>
    </Wrapper>
}

export default EditResult