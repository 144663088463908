import React, { useEffect } from "react";
import {
  HeadSectionLeft,
  HeadSectionRight,
  ProjectContainer,
  ProjectImage,
  ProjectTable,
  ProjectTableHead,
  ProjectTableWrap,
  UpdateNumber,
  VerticalLine,
  ProjectTopDiv,
  HeadList,
  ProjectContent,
  ProjectDiv,
  KeywordDiv,
  CompetitorsDiv,
  TextAdsDiv,
  ImageAdsDiv,
  SavedAdsDiv,
  KeywordWrap,
  ScrollWrapper,
  ViewMoreWraper,
  GenerateBtn,
  CompetitorsList,
  LoaderWrap,
} from "./index.styled";
import upArrow from "../../../../assests/upSmall.svg";
import Statics from "../../_static";
import { useDashboard } from "../../../../context/dashboard";
import downArrow from "../../../../assests/downSmall.svg";
import { useDashboardInfo } from "../../../../hooks/useDashboardInfo";
import { LineLoader } from "../../../../../../../globalStyles";
import { useAuth } from "../../../../../../../context/auth";

const Projects = () => {
  const { heading } = Statics;
  const {
    dashboardState: { toggleTable, projectList },
    dashboardDispatch,
  } = useDashboard();
  const { authState, authDispatch } = useAuth();
  const useDashboardInfoHook = useDashboardInfo();


  const toggleVisibility = (index) => {
    dashboardDispatch({
      type: "SET_TOGGLE_TABLE",
      value: toggleTable.includes(index)
        ? toggleTable.filter((i) => i !== index)
        : [...toggleTable, index],
    });
  };

  useEffect(() => {
    dashboardDispatch({type: "CLEAR_PROJECT"})
  }, [])
  
  return (
    <>
      {!projectList || useDashboardInfoHook.isIndividualProjectLoading ? (
        <LoaderWrap>
          <LineLoader />
        </LoaderWrap>
      ) : (
        <ProjectTableWrap>
          {projectList?.map((item, index) => {
            return (
              <ProjectTable
                onClick={() => {
                  authDispatch({type: "SET_SELECTED_PROJECT", value: item})
                  useDashboardInfoHook.getIndividualProject(item.id);
                  useDashboardInfoHook.pinProjectMutate({projectId: item.id})
                }}
              >
                <ProjectTableHead
                  toggleTable={toggleTable.includes(index)}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleVisibility(index);
                  }}
                >
                  <HeadSectionLeft>
                    {toggleTable.includes(index) ? (
                      <img src={upArrow} />
                    ) : (
                      <img
                        src={downArrow}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleVisibility(index);
                        }}
                      />
                    )}
                    <VerticalLine></VerticalLine>
                    <ProjectImage
                      src={
                        item.logo
                          ? item.logo
                          : `https://www.google.com/s2/favicons?domain=${
                              new URL(item.website).hostname
                            }`
                      }
                    />
                    <p>{item.projectName}</p>
                  </HeadSectionLeft>
                  <HeadSectionRight>
                    {/* <UpdateNumber>5</UpdateNumber>
                    <p>new updates</p> */}
                  </HeadSectionRight>
                </ProjectTableHead>
                {toggleTable.includes(index) && (
                  <ProjectContainer>
                    <ScrollWrapper>
                      <ProjectTopDiv>
                        {heading.map((title, idx) => {
                          return (
                            <HeadList widths={title.widthValue}>
                              <img src={title.projectLogo} />
                              <p>{title.name}</p>
                            </HeadList>
                          );
                        })}
                      </ProjectTopDiv>
                      <ProjectContent>
                        <ProjectDiv>
                          {item.website ? item.website : ""}
                        </ProjectDiv>
                        <KeywordDiv>
                          {item.keywords.length > 0 ? (
                            <KeywordWrap>
                              {item.keywords.slice(0, 5).map((keyword) => {
                                return <p>{keyword}</p>;
                              })}
                              {/* {item.keywords.length >= 5 && ( */}
                                <ViewMoreWraper>
                                  <span>view more</span>
                                  <img src={downArrow} />
                                </ViewMoreWraper>
                              {/* )} */}
                            </KeywordWrap>
                          ) : (
                            <ProjectDiv id="setupNow">
                              <ViewMoreWraper>+ setup now</ViewMoreWraper>
                            </ProjectDiv>
                          )}
                        </KeywordDiv>
                        <CompetitorsDiv>
                          {item.competitors.length > 0 ? (
                            <KeywordWrap>
                              {item.competitors
                                .slice(0, 5)
                                .map((track, colorIndex) => {
                                  const className =
                                    colorIndex % 2 === 0 ? "" : "evenClass";
                                  return (
                                    <CompetitorsList
                                      colorIndex={colorIndex}
                                      className={className}
                                    >
                                      {track}
                                    </CompetitorsList>
                                  );
                                })}
                              {/* {item.competitors.length >= 5 && ( */}
                                <ViewMoreWraper>
                                  <span>view more</span>
                                  <img src={downArrow} />
                                </ViewMoreWraper>
                              {/* )} */}
                            </KeywordWrap>
                          ) : (
                            <ProjectDiv id="setupNow">
                              <ViewMoreWraper>+ setup now</ViewMoreWraper>
                            </ProjectDiv>
                          )}
                        </CompetitorsDiv>
                        <TextAdsDiv>
                          {item.textAdsCount != 0 ? (
                            item.textAdsCount
                          ) : (
                            <GenerateBtn>Generate</GenerateBtn>
                          )}
                        </TextAdsDiv>
                        <ImageAdsDiv>
                          {item.imageAdsCount != 0 ? (
                            item.imageAdsCount
                          ) : (
                            <GenerateBtn>Generate</GenerateBtn>
                          )}
                        </ImageAdsDiv>
                        <SavedAdsDiv>
                          {item.savedAdsCount != "0" ? (
                            item.savedAdsCount
                          ) : (
                            <ViewMoreWraper id="saveAdCopy">
                              Explore adcopy & save now
                            </ViewMoreWraper>
                          )}
                        </SavedAdsDiv>
                      </ProjectContent>
                    </ScrollWrapper>
                  </ProjectContainer>
                )}
              </ProjectTable>
            );
          })}
        </ProjectTableWrap>
      )}
    </>
  );
};

export default Projects;
