import produce from "immer";
import { createContext, useContext, useReducer } from "react";

const initialState = {
  projectList: null,
  addProjectRestriction: {},
  selectedProject: null,
  selectedCountry: null,
  toggleTable: [0, 1, 2, 3],
  isAddNewPopupOpened: false,
  trackingKeywords: [],
  competitors: [],
  keywordChoices: null,
  competitorChoices: null,
  popupActive: false,
  isKeywordsSetupDone: false,
  isCompetitorsSetupDone: false,
  textAdsData: null,
  imageAdsData: null,
  keywordChartData: null,
  setupStatus: false,
  competitorsChart: [],
};

const dashboardReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_PROJECT_LIST":
      draft.projectList = action.value;
      break;

    case "SET_TOGGLE_TABLE":
      draft.toggleTable = action.value;
      break;

    case "SET_SELECTED_PROJECT":
      draft.selectedProject = action.value;
      break;

    case "SET_SELECTED_COUNTRY":
      draft.selectedCountry = action.value;
      break;

    case "SET_ADD_NEW_POPUP_OPENED":
      draft.isAddNewPopupOpened = action.value;
      break;

    case "SET_KEYWORD_CHOICES":
      draft.keywordChoices = action.value;
      break;

    case "SET_COMPETITOR_CHOICES":
      draft.competitorChoices = action.value;
      break;

    case "SET_TRACKING_KEYWORDS":
      draft.trackingKeywords = action.value;
      break;

    case "SET_COMPETITORS":
      draft.competitors = action.value;
      break;

    case "SET_POPUP_ACTIVE":
      draft.popupActive = action.value;
      break;

    case "SET_TEXTADS_DATA":
      draft.textAdsData = action.value;
      break;

    case "SET_IMAGEADS_DATA":
      draft.imageAdsData = action.value;
      break;

    case "SET_KEYWORD_SETUP_STATUS":
      draft.isKeywordsSetupDone = action.value
      break;

    case "SET_COMPETITOR_SETUP_STATUS":
      draft.isCompetitorsSetupDone = action.value
      break

    case "SET_KEYWORD_CHART_DATA":
      draft.keywordChartData = action.value
    break
    case "SET_SETUP_STATUS":
      draft.setupStatus = action.value
    break
    case "SET_COMPETITORS_CHART":
      draft.competitorsChart = action.value
    break

    case "SET_PROJECT_RESTRICTIONS":
      draft.addProjectRestriction = action.value
    break

    case "CLEAR_PROJECT":
      draft.selectedCountry = null
      draft.keywordChoices = null
      draft.competitorChoices = null
      draft.isKeywordsSetupDone = false
      draft.isCompetitorsSetupDone = false
      draft.selectedProject = null
    break

  }
});

const useDashboardReducer = () => useReducer(dashboardReducer, initialState);

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useDashboardReducer();

  const value = {
    dashboardState: state,
    dashboardDispatch: dispatch,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  return useContext(DashboardContext)
}
