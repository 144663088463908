import React, {useState} from "react";
import DownArrow from "../../../../../../../../../../media/Dashboard/uparrow.png"
import FacebookIcon from "../../../../../../../../../../media/Dashboard/facebook.svg"
import LinkedinIcon from "../../../../../../../../../../media/Dashboard/linkedin.svg"
import { Wrapper, SelectedPlatformDropdown, SelectedPlatform, DropDown, DropDownOption, PreviewElement, AudienceContainer, BudgetContainer, PublishButton, PreviewAdCard, PreviewAdHeader, HeaderTitleWrapper, PreviewAdDescription } from "./index.styled"
import ImageAdResultDummy from "../../../../../../../../../../media/Dashboard/ImageAdResultDummy.svg"
import GlobeIcon from "../../../../../../../../../../media/Dashboard/globeIcon.svg"
import TreeDotsIcon from "../../../../../../../../../../media/Dashboard/threedots.svg"

import { Divider } from "../../index.styled";


const Preview = () => {
    const [selectedPlatform, setSelectedPlatform] = useState(0);
    const [dropDownOpen, setDropwDownOpen] = useState(false);

    const PlatformList = [{
        name: "Facebook",
        icon: FacebookIcon
    },{
        name: "Linkedin",
        icon: LinkedinIcon
    }];

    return <Wrapper>
        <h2>Publish this ad</h2>
        <SelectedPlatformDropdown>
            <SelectedPlatform dropDownOpen={dropDownOpen} onClick={()=>setDropwDownOpen(prev => !prev)}>
                <div>
                    <img src={PlatformList[selectedPlatform].icon}/>
                    <p>{PlatformList[selectedPlatform].name}</p>
                </div>
                <img className="arrow" src={DownArrow}/>
            </SelectedPlatform>
            {dropDownOpen && <DropDown>
                {
                    PlatformList.map((platform)=>{
                        return <DropDownOption onClick={()=>setSelectedPlatform(platform.name)}>
                            <img src={platform.icon}/>{platform.name}
                        </DropDownOption>
                    })
                }
            </DropDown>}
        </SelectedPlatformDropdown>
        <PreviewElement>
            <PreviewAdCard>
                <PreviewAdHeader>
                    <img src={`https://www.google.com/s2/favicons?domain=hubspot.com`}/>
                    <HeaderTitleWrapper>
                        <h3>Hubspot</h3>
                        <p>Sponsored<img src={GlobeIcon}/></p>
                    </HeaderTitleWrapper>
                    <img className="three-dots" src={TreeDotsIcon}/>
                </PreviewAdHeader>
                <PreviewAdDescription>
                    Watch this HubSpot Academy On-the-go video to discover how LinkedIn CRM Sync can streamline your processes and boost you…
                </PreviewAdDescription>
                <img className="ad-image" src={ImageAdResultDummy}/>
            </PreviewAdCard>
        </PreviewElement>
        <AudienceContainer>
            <label>
            Estimated target audience
            </label>
            <p>2,458,759</p>
        </AudienceContainer>
        <Divider/>
        <BudgetContainer>
            <label>
            Recommended budget
            </label>
            <p>$5.00 - $25.00 <span>/ per day</span></p>
        </BudgetContainer>
        <PublishButton>
            Publish
        </PublishButton>
    </Wrapper>
}

export default Preview