import React, {useState} from "react";
import DownArrow from "../../../../../../../../../../media/Dashboard/uparrow.png"
import FacebookIcon from "../../../../../../../../../../media/Dashboard/facebook.svg"
import LinkedinIcon from "../../../../../../../../../../media/Dashboard/linkedin.svg"
import { Wrapper, SelectedCtaDropdown, SelectedPlatform, DropDown, DropDownOption } from "./index.styled"


const Cta = () => {
    const [selectedPlatform, setSelectedPlatform] = useState(0);
    const [dropDownOpen, setDropwDownOpen] = useState(false);

    const PlatformList = [{
        name: "Shop now"
    },{
        name: "Buy Now"
    },{
        name: "Download Now"
    }];

    return <Wrapper>
        <h2>CTA</h2>
        <SelectedCtaDropdown>
            <SelectedPlatform dropDownOpen={dropDownOpen} onClick={()=>setDropwDownOpen(!dropDownOpen)}>
                <div>
                    <p>{PlatformList[selectedPlatform].name}</p>
                </div>
                <img src={DownArrow}/>
            </SelectedPlatform>
            {dropDownOpen && <DropDown>
                {
                    PlatformList.map((platform)=>{
                        return <DropDownOption onClick={()=>setSelectedPlatform(platform.name)}>
                            {platform.name}
                        </DropDownOption>
                    })
                }
            </DropDown>}
        </SelectedCtaDropdown>
    </Wrapper>
}

export default Cta