import React, { useState } from "react";
import {
  ActiveCampaign,
  AdsyderDiv,
  CampaignOuterWrap,
  CampaignTableWrap,
  ImpressionDiv,
  LeftArrow,
  NameDiv,
  PaginationWrapper,
  PerformanceDiv,
  PlatformDiv,
  RightArrow,
  Row,
  RowsList,
  ScrollWraper,
  TableBody,
  TableHeader,
  TableWrap,
} from "./index.styled";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  ColumnDef,
  getSortedRowModel,
} from "@tanstack/react-table";
import downArrow from "../../../../../../../../media/Dashboard/arrow-down.svg";
import GoogleLogo from "../../../../../../../../media/Dashboard/Google16.svg";
import BingLogo from "../../../../../../../../media/Dashboard/Bing16.svg";
import YoutubeLogo from "../../../../../../../../media/Dashboard/YouTube16.svg";
import MetaLogo from "../../../../../../../../media/Dashboard/meta16.png";
import LinkedinLogo from "../../../../../../../../media/Dashboard/LinkedIn16.svg";
import RedditLogo from "../../../../../../../../media/Dashboard/Reddit16.svg";
import GooglePlaLogo from "../../../../../../../../media/Dashboard/Googlepla16.svg";
import BingPlaLogo from "../../../../../../../../media/Dashboard/Bingpla16.svg";
import AmazonLogo from "../../../../../../../../media/Dashboard/amazon16.svg";
import DisplayLogo from "../../../../../../../../media/Dashboard/Display16.svg";
import GaugeComponent from "react-gauge-component";
import arrowOrange from "../../../../../../../../media/Dashboard/arrow-right-orange.svg";
import paginateArrow from "../../../../../../../../media/Dashboard/arrow-right.svg";
import greenLiveImg from "../../../../../../../../media/Dashboard/greenLive.svg";
import adResultData from "../../../../static";

const CampaignComponent = () => {
  const columnHelper = createColumnHelper();
  const { dummyData } = adResultData;
  const PlatformList = {
    google: GoogleLogo,
    bing: BingLogo,
    youtube: YoutubeLogo,
    facebook: MetaLogo,
    linkedin: LinkedinLogo,
    reddit: RedditLogo,
    googleEcom: GooglePlaLogo,
    bingEcom: BingPlaLogo,
    amazon: AmazonLogo,
    display: DisplayLogo,
  };

  const columns = [
    columnHelper.accessor("no", {
      id: "no",
      header: () => "No",
      cell: (info) => info.getValue(),
    }),
  ];
  const table = useReactTable({
    data: "accou",
    columns,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [currentPage, setCurrentPage] = useState(1);

  let rowsPerPage = 10;
  const totalPages = Math.ceil(dummyData.length / rowsPerPage);
  const currentData = dummyData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, dummyData.length);
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
  return (
    <CampaignOuterWrap>
      <h3>Campaigns</h3>
      <CampaignTableWrap>
        <ActiveCampaign>Active campaigns</ActiveCampaign>
        <ScrollWraper>
          <TableWrap>
            {table.getHeaderGroups().map((headerGroup) => {
              // console.log(headerGroup)
              return (
                <TableHeader>
                  <div id="campaignHead">Campaign</div>
                  <div id="plaformHead">Platforms</div>
                  <div id="impressionHead">Impression</div>
                  <div id="impressionHead">Click</div>
                  <div id="impressionHead">CT. Rate</div>
                  <div id="impressionHead">Total Spent</div>
                  <div id="performanceHead">
                    Performance Score <img src={downArrow} />
                  </div>
                  <div id="adspyderHead">Adspyder</div>
                </TableHeader>
              );
            })}
            <TableBody>
              {currentData.map((row, index) => {
                return (
                  <Row>
                    <NameDiv>{row.campaignName}</NameDiv>
                    <PlatformDiv>
                      <img src={PlatformList[row.platforms]} />
                    </PlatformDiv>
                    <ImpressionDiv>{row.impressions}</ImpressionDiv>
                    <ImpressionDiv>{row.itemClick}</ImpressionDiv>
                    <ImpressionDiv>{row.CTRate}</ImpressionDiv>
                    <ImpressionDiv>{row.TotalSpent}</ImpressionDiv>
                    <PerformanceDiv>
                      <p>
                        <img src={greenLiveImg} />
                        {row.PerformanceScore}
                      </p>
                    </PerformanceDiv>
                    <AdsyderDiv>
                      <GaugeComponent
                        value={50}
                        type="radial"
                        labels={{
                          tickLabels: {
                            hideMinMax: true,
                          },
                          valueLabel: {
                            hide: true,
                          },
                        }}
                        hideText={false}
                        arc={{
                          colorArray: [
                            "#9BFF00",
                            "#00D200",
                            "#FFE600",
                            "#FFAA32",
                            "#FF1E5A",
                            "#C40050",
                          ],
                          cornerRadius: 0,
                          subArcs: [
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                          ],
                          padding: 0,
                          width: 0.45,
                        }}
                        pointer={{
                          elastic: false,
                          animationDelay: 100,
                          color: "#001428",
                          width: 37,
                        }}
                      />
                      <img src={arrowOrange} height={20} />
                    </AdsyderDiv>
                  </Row>
                );
              })}
            </TableBody>
          </TableWrap>
        </ScrollWraper>

        <PaginationWrapper>
          <RowsList>
            <span>
              Rows {startRow}-{endRow}
            </span>{" "}
            of {dummyData.length} campaigns
          </RowsList>
          <LeftArrow onClick={handlePrevPage} disabled={currentPage === 1}>
            <img src={paginateArrow} />
          </LeftArrow>
          <RightArrow
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <img src={paginateArrow} />
          </RightArrow>
        </PaginationWrapper>
      </CampaignTableWrap>
    </CampaignOuterWrap>
  );
};

export default CampaignComponent;
