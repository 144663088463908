import {
  Asset,
  Assets,
  BrandLogo,
  Container,
  Edit,
  EditContainer,
  Label,
  Logo,
  LogoContainer,
  Property,
  Value,
  LogoWrapper,
  ColorsWrapper,
  Color,
  ColorsContainer,
  AddColor,
  Add,
  BrandImage,
  Gallery,
  UploadImage,
  Upload,
  List,
  ImageContainer,
  Selected,
  Image,
  Navigator,
  NavButton,
  NextButton,
  InputDummy,
  ColorpickerContainer,
  LableNote,
  ColorFooter,
  OkButton,
} from "./index.styled";
import { ChromePicker } from "react-color";

import tempImg1 from "./assets/tempImg1.png";
import tempImg2 from "./assets/tempImg2.png";
import tempImg3 from "./assets/tempImg3.png";
import tempImg4 from "./assets/tempImg4.png";
// import tempLogo from "./assets/temp-brand-logo.svg";
import edit from "./assets/edit.svg";
import add from "./assets/add.svg";
import upload from "./assets/upload.svg";
import selected from "./assets/selected.svg";
import left from "./assets/left.svg";
import right from "./assets/right.svg";
import { useEffect, useRef, useState } from "react";
import statics from "../../../../statics";
import AnalyzingLoader from "../../../AnalyzingLoader";
import { useAdGenerator } from "../../../../context/adGeneration";
import { useTextAdGenInfo } from "../../../../hooks/useTextAdGenInfo";
import { useAuth } from "../../../../../../../context/auth";
import { useImageAdGenInfo } from "../../../../hooks/useImageAdGenInfo";

// const stockImages = [
//   tempImg1,
//   tempImg2,
//   tempImg3,
//   tempImg4,
//   tempImg1,
//   tempImg2,
// ];

function BrandDetails({ onNext, forTextAd }) {
  const { steps } = statics;
  const [selectedImage, setSelectedImage] = useState([]);

  // const [selectedIndices, setSelectedIndices] = useState([]);
  const { authState, authDispatch } = useAuth();

  const imagesListRef = useRef(null);
  const selectedLogoRef = useRef(null);
  const {
    adGeneratorState: {
      brandDetails,
      goal,
      destinationUrl,
      generatedAdId,
      wesbiteMetaLoading,
      selectedIndices,
    },
    adGeneratorDispatch,
  } = useAdGenerator();
  const { adGeneratorState } = useAdGenerator();
  const [hex, setHex] = useState("#d29c9c");
  const colorPickerContainerRef = useRef(null);
  const {
    isUserAdIdLoading,
    isWebsiteMetaLoading,
    getUserAdIdMutate,
    getWebsiteMetaMutate,
  } = useTextAdGenInfo();
  const { name, description, productDetails, colors, images, logo } =
    brandDetails;
  const { getCreateCampaign, isCreateCampaignLoading, getProcessedState } =
    useImageAdGenInfo();

  useEffect(() => {
    forTextAd
      ? getUserAdIdMutate({
          adGoal: goal,
          adGoalDescription: adGeneratorState.description,
          destinationUrl: destinationUrl,
          projectId: authState.defaultProjectInfo.projectId,
        })
      : getCreateCampaign({
          adGoal: goal,
          adExtraDetails: adGeneratorState.description,
          destinationUrl: destinationUrl,
          projectId: authState.defaultProjectInfo.projectId,
        });
  }, []);

  useEffect(() => {
    if (generatedAdId) {
      if (forTextAd) {
        adGeneratorDispatch({
          type: "SET_WEBSITE_META_LOADING",
          value: true,
        });
        getWebsiteMetaMutate({
          userAdId: generatedAdId,
          projectId: authState.defaultProjectInfo.projectId,
        });
      } else {
        adGeneratorDispatch({
          type: "SET_WEBSITE_META_LOADING",
          value: true,
        });
        getProcessedState({
          campaignId: generatedAdId,
          projectId: authState.defaultProjectInfo.projectId,
        });
      }
    }
  }, [generatedAdId]);

  useEffect(() => {
    // setTimeout(() => {
    //     if(!name){
    //         adGeneratorDispatch({
    //             type: "SET_BRAND_DETAILS",
    //             value: {...brandDetails, name: steps[3].brandName }
    //         })
    //     }
    // }, 2000)
    // setTimeout(() => {
    //     if(!description){
    //         adGeneratorDispatch({
    //             type: "SET_BRAND_DETAILS",
    //             value: {...brandDetails, name: steps[3].brandName, description: steps[3].brandDescription }
    //         })
    //     }
    // }, 4000)
    // setTimeout(() => {
    //     if(!productDetails){
    //         adGeneratorDispatch({
    //             type: "SET_BRAND_DETAILS",
    //             value: {...brandDetails, name: steps[3].brandName, description: steps[3].brandDescription, productDetails: steps[3].productDetails }
    //         })
    //     }
    // }, 6000)
  }, []);

  useEffect(() => {}, [images]);

  const handleImageClick = (img, alreadySelected) => {
    console.log(selectedIndices, "selectedIndices");

    if (alreadySelected) {
      adGeneratorDispatch({
        type: "SET_SELECTED_INDICES",
        value: selectedIndices.filter((pic) => pic !== img),
      });
      return;
    }
    adGeneratorDispatch({
      type: "SET_SELECTED_INDICES",
      value: [...selectedIndices, img],
    });
  };
  console.log(selectedIndices, "selectedIndices");

  const handleScroll = (direction) => {
    const imagesList = imagesListRef.current;
    if (imagesList) {
      switch (direction) {
        case "left":
          imagesList.scrollBy(-200, 0);
          break;

        case "right":
          imagesList.scrollBy(200, 0);
          break;
      }
    }
  };

  const handleInputChanges = (event, property) => {
    adGeneratorDispatch({
      type: "SET_BRAND_DETAILS",
      value: { ...brandDetails, [property]: event.target.value },
    });
  };

  const handleNext = () => {
    onNext();
  };

  const handleFileChange = async (event, property) => {
    const image = URL.createObjectURL(event.target.files[0]);
    const isLogoChange = property === "logo";
    adGeneratorDispatch({
      type: "SET_BRAND_DETAILS",
      value: {
        ...brandDetails,
        [property]: isLogoChange ? image : [image, ...brandDetails.images],
      },
    });
    if (isLogoChange && selectedLogoRef.current)
      selectedLogoRef.current.setAttribute("src", image);
  };

  const handleColorPicker = () => {
    console.log("clciked");
    colorPickerContainerRef.current.style.display = "block";
  };

  const onColorPickerChangeComplete = (e) => {
    e.stopPropagation();
    colorPickerContainerRef.current.style.display = "none";
    adGeneratorDispatch({
      type: "SET_BRAND_DETAILS",
      value: { ...brandDetails, colors: [...colors, hex] },
    });
  };
  console.log(logo, "logo");
  return (
    <Container>
      {wesbiteMetaLoading || isUserAdIdLoading ? (
        <AnalyzingLoader text="Analyzing the URL..." />
      ) : (
        <>
          {/* {name && ( */}
          <Property>
            <Label>Brand name</Label>
            <Value
              short
              value={name}
              onChange={(e) => handleInputChanges(e, "name")}
            />
          </Property>
          {/* )} */}
          {/* {description && ( */}
          <Property>
            <Label>
              Brand description{" "}
              <span>({authState.defaultProjectInfo.website})</span>
            </Label>
            <Value
              value={description}
              onChange={(e) => handleInputChanges(e, "description")}
            />
            {/* <Value>{description}</Value> */}
          </Property>
          {/* )} */}
          {/* {productDetails && ( */}
          <Property>
            <Label style={{ marginBottom: "8px" }}>
              How would you describe the focus of your landing page targeting
              ad?<span>({adGeneratorState.destinationUrl})</span>
            </Label>
            <LableNote>
              *A Good description will improve the quality of your Ad Generating
            </LableNote>
            <Value
              value={productDetails}
              onChange={(e) => handleInputChanges(e, "productDetails")}
            />
            {forTextAd && (
              <NextButton
                isDisabled={!name || !description || !productDetails}
                forTextAd
                onClick={handleNext}
              >
                Next
              </NextButton>
            )}
          </Property>
          {/* )} */}
          {!forTextAd && (
            <>
              <Assets style={{ width: "72.4%" }}>
                <Asset>
                  <Label>Logo</Label>
                  <LogoWrapper>
                    <LogoContainer>
                      <BrandLogo
                        ref={selectedLogoRef}
                        src={logo}
                        draggable={false}
                      />
                    </LogoContainer>
                    <EditContainer>
                      <Edit src={edit} draggable={false} />
                      <InputDummy
                        type="file"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={(event) => handleFileChange(event, "logo")}
                      />
                    </EditContainer>
                  </LogoWrapper>
                </Asset>
                <Asset style={{ width: "calc(100% - 185px - 56px)" }}>
                  <Label>Colors</Label>
                  <ColorsWrapper>
                    <ColorsContainer>
                      {colors.map((color) => {
                        return <Color color={color} />;
                      })}
                    </ColorsContainer>
                    <AddColor onClick={handleColorPicker}>
                      <Add src={add} draggable={false} />
                      <ColorpickerContainer ref={colorPickerContainerRef}>
                        <ChromePicker
                          // onChangeComplete={onColorPickerChangeComplete}
                          color={hex}
                          style={{ float: "left" }}
                          // placement={GithubPlacement.Right}
                          onChange={(color) => {
                            setHex(color.hex);
                          }}
                          disableAlpha={true}
                        />
                        <ColorFooter colorActive={hex}>
                          <p></p>
                          <OkButton
                            onClick={(e) => onColorPickerChangeComplete(e)}
                          >
                            ok
                          </OkButton>
                        </ColorFooter>
                        {/* <Demo /> */}
                      </ColorpickerContainer>
                    </AddColor>
                  </ColorsWrapper>
                </Asset>
              </Assets>

              <BrandImage>
                <Label>
                  Images
                  <UploadImage>
                    <Upload src={upload} draggable={false} />
                    Upload
                    <InputDummy
                      type="file"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => handleFileChange(event, "images")}
                    />
                  </UploadImage>
                </Label>
                <Gallery>
                  Select upto 3 images
                  <List ref={imagesListRef}>
                    {images.map((img, index) => {
                      const isSelected = selectedIndices?.includes(img);
                      return (
                        <ImageContainer
                          isSelected={isSelected}
                          onClick={() => handleImageClick(img, isSelected)}
                        >
                          <Image
                            src={img}
                            draggable={false}
                            isSelected={isSelected}
                          />
                          {isSelected && (
                            <Selected src={selected} draggable={false} />
                          )}
                        </ImageContainer>
                      );
                    })}
                    {/* {stockImages.length > 0 &&
                      stockImages.map((img, index) => {
                        const isSelected = selectedIndices?.includes(index);
                        return (
                          <ImageContainer
                            isSelected={isSelected}
                            onClick={() =>
                              handleImageClick(index, isSelected, "stockImages")
                            }
                          >
                            <Image
                              src={img}
                              draggable={false}
                              isSelected={isSelected}
                            />
                            {isSelected && (
                              <Selected src={selected} draggable={false} />
                            )}
                          </ImageContainer>
                        );
                      })} */}
                  </List>
                  <Navigator left>
                    <NavButton
                      src={left}
                      draggable={false}
                      onClick={() => handleScroll("left")}
                    />
                  </Navigator>
                  <Navigator right>
                    <NavButton
                      src={right}
                      draggable={false}
                      onClick={() => handleScroll("right")}
                    />
                  </Navigator>
                </Gallery>
                <NextButton
                  isDisabled={
                    !name ||
                    !description ||
                    !productDetails ||
                    !colors.length ||
                    !logo ||
                    !selectedIndices.length
                  }
                  onClick={handleNext}
                >
                  Next
                </NextButton>
              </BrandImage>
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default BrandDetails;
