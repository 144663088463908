import React, { useState } from "react";
import {
  AdHeadWrap,
  AdHeader,
  AdHeaderContainer,
  AdResultWrap,
  CalendarContainer,
  CalendarDropDown,
  CalenderWrap,
  ManageButton,
  ManageWrap,
  PlatformAccount,
  PlatformAccountWrap,
  ResultWrapper,
} from "./index.styled";
import calendarImg from "../../../../../../media/Dashboard/calendarAd.svg";
import rightArrowImg from "../../../../../../media/Dashboard/arrow-right.svg";
import downArrowImg from "../../../../../../media/Dashboard/angle-down.svg";
import checkImg from "../../../../../../media/Dashboard/check.svg";
import markImg from "../../../../../../media/Dashboard/mark.svg";
import adsIcon from "../../../../../../media/Dashboard/ads-1.svg";
import adResultData from "../../static";
import AccountDataDetails from "./components/AccountDataDetails";
import CampaignComponent from "./components/CampaignComponent";
import DetailsComponent from "./components/DetailsComponent";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";
import { extendMoment } from "moment-range";
import { useAdIntegration } from "../../context/AdManagementContext";
import ManagePopup from "./components/ManagePopup";
import SelectAccount from "../InputPages/components/selectAccount";
// const moment = extendMoment(originalMoment);

const ResultPages = () => {
  const { platformArray } = adResultData;
  const today = moment();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );
  const {
    newAdManagementState: { manageAccount, selectAdAccount },
    newAdManagementDispatch,
  } = useAdIntegration();

  const onSelect = (value, states) => {
    console.log(value);
    setValue(value);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ResultWrapper>
      <AdResultWrap>
        <AdHeadWrap>
          <AdHeaderContainer>
            <AdHeader>
              Ad Account Overview - <span>flonnect</span>
            </AdHeader>
            <p>
              Detailed Insights into Campaign Performance, Audience Behavior,
              and Budget Utilization
            </p>
          </AdHeaderContainer>
          <CalenderWrap>
            <CalendarContainer CalendarContainer onClick={onToggle}>
              <img src={calendarImg} />
              {value.start.format("MMM D, YYYY")}
              <img src={rightArrowImg} />
              {value.end.format("MMM D, YYYY")}
              <img src={downArrowImg} />
            </CalendarContainer>
            {isOpen && (
              <CalendarDropDown>
                <DateRangePicker
                  value={value}
                  onSelect={onSelect}
                  singleDateRange={true}
                />
              </CalendarDropDown>
            )}
          </CalenderWrap>
        </AdHeadWrap>
        <ManageWrap>
          <PlatformAccountWrap>
            {platformArray.map((item) => (
              <PlatformAccount isConnect={item.connected}>
                <img src={item.platform} height={"20px"} />
                <p>{item.title}</p>
                <img
                  src={item.connected ? checkImg : markImg}
                  height={"24px"}
                />
              </PlatformAccount>
            ))}
          </PlatformAccountWrap>
          <ManageButton
            onClick={() =>
              newAdManagementDispatch({
                type: "SET_MANAGE_ACCOUNT",
                value: true,
              })
            }
          >
            <img src={adsIcon} />
            Manage
          </ManageButton>
        </ManageWrap>
        <AccountDataDetails />
        <CampaignComponent value={65} maxValue={100} />
        <DetailsComponent />
        {manageAccount && <ManagePopup />}
        {selectAdAccount && <SelectAccount />}
      </AdResultWrap>
    </ResultWrapper>
  );
};

export default ResultPages;
