import React from "react";
import styled, { keyframes } from "styled-components";
import { useSearchParams, useNavigate } from "react-router-dom";

import KeywordSearchType from "./Type";

/* Broad components */
import BroadKeywordsInput from "./BroadComponents/KeywordsInput";

/* Phrase components */
import PhraseInput from "./PhraseComponents/Input";


import { useSearch } from "./../../../context/search";
import { useAuth } from "../../../../../../../context/auth";

const Button = styled.button`
  // width: 15%;
  // min-width: 75px;
  padding: 10px 18px;
  height: 44px;
  // max-width: 80px;

  /* margin-left: 8px; */

  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  /* margin-top: 20px; */
  border-radius: 8px;
  border: 1px solid #FF711E;
  background: #FF711E;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-left: 7px;

  :hover {
    // filter: brightness(90%);
    border: 1px solid #EE6819;
    background: #EE6819;
  }
`;

const SpinAnime = keyframes`
  0% { -webkit-transform: rotate(0deg);}
  100% { -webkit-transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 2px dotted #dbf2ff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-right: 2px solid #018df7;
  text-align: center;
  animation-name: ${SpinAnime};
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
  margin-left: 1rem;
`;

const ToolTipContainer = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  img {
    margin-left: 4px;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;
const ExampleSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 18px 0 73px 0;
  h3 {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: #1c1c1c;
    /* margin-right: 6px; */
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #368cd5;
    cursor: pointer;
    margin-right: 4px;
  }
`;
const ExampleWrap = styled.div`
  max-width: 440px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const KeywordComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { authState, authDispatch } = useAuth();
  const { searchState, searchDispatch } = useSearch();

  const navigate = useNavigate();
  const redirectUrl = (input, mode) => {
    if (mode == "keyword") {
      navigate(
        `/adspy/${authState.searchPlatform}?searchMode=${mode}&keywordSearchType=phrase&phraseInput=${input}&page=1&excludedTerm=&searchIn=title%2Ccontent&sortType=latest`
      );
    } else if (mode == "url") {
      navigate(
        `/adspy/${authState.searchPlatform}?searchMode=${mode}&urlSearchType=exact&domainUrlInput=${input}&page=1&sortType=latest`
      );
    }
  };

  const handleSearch = () => {
    const { searchMode } = authState;

    if (!authState.searchPlatform) {
      return authDispatch({ type: "SET_SEARCH_PLATFORM_ERROR", value: true });
    }

    if (!authState.features?.allowedPlatforms.includes(authState.searchPlatform)) {
      authDispatch({ type: 'UPDATE_IS_RESTRICTED', value: true })
    } else if (searchMode === "keyword") {
      // console.log("2", authState)
      const { keywordSearchType } = authState
      if (keywordSearchType === "broad") {
        const { broadMinShouldMatch, keywords } = authState

        if (keywords.length == 0) {
          return authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: true })
        }

        let broadSearchIn = ["content", "title"]
        if (authState.searchPlatform == "meta") {
          broadSearchIn = broadSearchIn.concat(["innertitle", "innerdescription"])
        }

        authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: keywords })

        let paramQuery = keywords.join(",")

        searchParams.set('searchMode', "keyword")
        searchParams.set('keywordSearchType', "broad")
        searchParams.set('keywords', paramQuery);
        searchParams.set('page', 1);
        searchParams.set('excludedTerm', "");
        searchParams.set('minimumShouldMatch', broadMinShouldMatch.value);
        searchParams.set('searchIn', broadSearchIn.join(","));
        searchParams.set('sortType', 'latest');
        searchParams.delete('selectedCountries');
        searchParams.delete('startDate');
        searchParams.delete('endDate');
        searchParams.delete('cta');
        searchParams.delete('tone');
        searchParams.delete('otherFilters');
        searchParams.delete('sentiment');
        // setSearchParams(searchParams)                

        navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

      } else if (keywordSearchType === "phrase") {
        const { phraseInput } = authState
        if (phraseInput.trim().length == 0) {
          return authDispatch({ type: 'SET_KEYWORD_INPUT_ERROR', value: true })
        }

        let phraseSearchIn = ["content", "title"]
        if (authState.searchPlatform == "meta") {
          phraseSearchIn = phraseSearchIn.concat(["innertitle", "innerdescription"])
        }

        authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: phraseInput.split(" ") })

        let paramQuery = phraseInput.trim()

        searchParams.set('searchMode', "keyword")
        searchParams.set('keywordSearchType', "phrase")
        searchParams.set('phraseInput', paramQuery);
        searchParams.set('page', 1);
        searchParams.set('excludedTerm', "");
        searchParams.set('searchIn', phraseSearchIn.join(","));
        searchParams.set('sortType', 'latest');
        searchParams.delete('selectedCountries');
        searchParams.delete('startDate');
        searchParams.delete('endDate');
        searchParams.delete('cta');
        searchParams.delete('tone');
        searchParams.delete('otherFilters');
        searchParams.delete('sentiment');
        // setSearchParams(searchParams)
        navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

      }
    }
  };

  return (
    <React.Fragment>
      <KeywordSearchType />
      <ExampleWrap>
        {authState.keywordSearchType === "broad" ? (
          <React.Fragment>
            <BroadKeywordsInput />
            {/* <BroadMinShouldMatch/> */}
            {/* <ExcludeTerm/> */}
            {/* <BroadSearchIn/> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PhraseInput />
            {/* <ExcludeTerm/> */}
            {/* <PhraseSearchIn/> */}
          </React.Fragment>
        )}
        {authState.searchMode === "keyword" ? (
          <ExampleSearchContainer>
            <h3>
              Example:
              {/* <img src={TrendingIcon}/>:  */}
            </h3>
            <span onClick={() => redirectUrl("apple", "keyword")}>apple</span>
            <span onClick={() => redirectUrl("amazon", "keyword")}>amazon</span>
            <span onClick={() => redirectUrl("semrush", "keyword")}>
              semrush
            </span>
          </ExampleSearchContainer>
        ) : (
          <ExampleSearchContainer>
            <h3>Example:</h3>
            <span onClick={() => redirectUrl("apple.com", "url")}>
              apple.com
            </span>
            <span onClick={() => redirectUrl("amazon.in", "url")}>
              amazon.in
            </span>
            <span onClick={() => redirectUrl("flipkart.com", "url")}>
              flipkart.com
            </span>
          </ExampleSearchContainer>
        )}
      </ExampleWrap>
      <Button onClick={handleSearch}>Search</Button>

      {/* {(isKeywordBroadLoading || isKeywordPhraseLoading) && <Spinner />} */}
    </React.Fragment>
  );
};

export default KeywordComponent;
