import { useEffect } from "react";
import { useDashboard } from "../context/dashboard";
import { Container, Wrapper } from "./index.styled";
import ProjectList from "./ProjectList";
import ProjectOverview from "./ProjectOverview";
import AddNewProject from "./AddProject";
import { useDashboardInfo } from "../hooks/useDashboardInfo";
import { useAuth } from "../../../../context/auth";
import LimitExceededPopup from "../../../components/limitExceededPopup";
function Root() {
  const {
    dashboardState: { selectedProject, popupActive, projectList, addProjectRestriction: {allowedProjectCount, projectCountStillCanAdd}},
    dashboardDispatch
  } = useDashboard();
  const useDashboardInfoHook = useDashboardInfo();

  const { authState, authDispatch } = useAuth()

  useEffect(() => {
    if(authState.selectedProject){
      useDashboardInfoHook.getIndividualProject(authState.selectedProject.id);
    }
  }, [authState.selectedProject])

  const closePopup = () => {
    dashboardDispatch({
      type: "SET_POPUP_ACTIVE",
      value: false
    })
  }

  return (
    <Wrapper>
      <Container paddingProps={selectedProject && projectList.length != 1 ? true : false}>
        {selectedProject ? <ProjectOverview /> : <ProjectList />}
      </Container>
      {popupActive && (projectCountStillCanAdd > 0 ?  <AddNewProject /> : <LimitExceededPopup closeEnabled onClose={closePopup} err="add-project-quota-expired" /> ) }
    </Wrapper>
  );
}

export default Root;
