import AnalyzingLoader from "../../../../AnalyzingLoader";
import { CheckBox, Choice, ChoiceContainer, ChoicesList, Container, Generate, GenerateContainer, Image, Input, KeywordContainer, Label, Panel, SelectedKeywords, SelectedPanel, SelectingPanel, Window } from "./index.styled";

import checked from "./assets/checked.svg";
import unchecked from "./assets/unchecked.svg";
import close from "./assets/close.svg";
import statics from "../../../../../statics";
import { useRef, useState } from "react";
import { useAdGenerator } from "../../../../../context/adGeneration";
import { useTextAdGenInfo } from "../../../../../hooks/useTextAdGenInfo";
import { useAuth } from "../../../../../../../../context/auth";
import { findCriteriaIdByCountry } from "../../../../../../../../utils/helper";

function SeedKeywords() {

    const { steps } = statics
    const { text: { recommendingKeywords } } = steps[5]
    const { adGeneratorState, adGeneratorDispatch } = useAdGenerator()
    const { generateAdMutate } = useTextAdGenInfo()

    const { authState, authDispatch } = useAuth()
    const [selectedKeywords, setSelectedKeywords] = useState([])

    const keywordRef = useRef(null)

    const toggleSelection = (selectedKeyword) => {
        if (selectedKeywords?.includes(selectedKeyword)) {
            setSelectedKeywords(selectedKeywords.filter((keyword) => keyword !== selectedKeyword));
        } else {
            setSelectedKeywords([...selectedKeywords, selectedKeyword]);
        }
    };

    
    const onGenerate = () => {
        // console.log(adGeneratorState.targetAudience.locations.map((loc)=> {
        //     return findCriteriaIdByCountry(loc)
        // }), adGeneratorState.targetAudience, adGeneratorState.targetAudience.locations)
        generateAdMutate({
            userAdId: adGeneratorState.generatedAdId,
            projectId: authState.defaultProjectInfo.projectId,
            brandName: adGeneratorState.brandDetails.name,
            productDetails: adGeneratorState.brandDetails.productDetails,
            productDescription: adGeneratorState.brandDetails.description,
            age: adGeneratorState.targetAudience.ageGroup,
            gender: adGeneratorState.targetAudience.gender,
            language: adGeneratorState.targetAudience.languages[0],
            occupation: adGeneratorState.targetAudience.occupations,
            location: adGeneratorState.targetAudience.locations.map((loc)=> {
                return findCriteriaIdByCountry(loc)
            }),
            // [
            //     findCriteriaIdByCountry(adGeneratorState.targetAudience.locations)
            // ],
            seedKeywords: selectedKeywords
        })
    }

    const handleKeydown = (event) => {
        if (event.key === "Enter") {
            toggleSelection(keywordRef.current.value)
            keywordRef.current.value = ""
        }
    }

    return (
        <Container>
            {/* <AnalyzingLoader text={"Recommending seed keywords..."} /> */}
            <SelectingPanel>
                <Panel>
                    <Label>Recommendation</Label>
                    <Window recommendation >
                        <ChoicesList>
                            {adGeneratorState.keywordSuggestion?.map((keyword, index) => {
                                const isChecked = selectedKeywords?.includes(keyword);
                                return (
                                    <ChoiceContainer key={index} onClick={() => toggleSelection(keyword)} >
                                        <Choice isSelected={isChecked} >
                                            {keyword}
                                        </Choice>
                                        <CheckBox isChecked={isChecked} >
                                            <Image src={isChecked ? checked : unchecked} draggable={false} />
                                        </CheckBox>
                                    </ChoiceContainer>
                                )
                            })}
                        </ChoicesList>
                    </Window>
                </Panel>
                <Panel>
                    <Label>Seed keywords</Label>
                    <Window>
                        <SelectedPanel>
                            <Input
                                placeholder="Type or Add keywords from the left panel"
                                ref={keywordRef}
                                onKeyDown={handleKeydown}
                            />
                            <SelectedKeywords>
                                {selectedKeywords && selectedKeywords?.map((keyword, ind) => {
                                    return (
                                        <KeywordContainer odd={ind % 2 !== 0} >
                                            {keyword}
                                            <Image src={close} button draggable={false} onClick={() => toggleSelection(keyword)} />
                                        </KeywordContainer>
                                    )
                                })}
                            </SelectedKeywords>
                        </SelectedPanel>
                    </Window>
                </Panel>

            </SelectingPanel>
            <GenerateContainer>
                <Generate isDisabled={selectedKeywords.length == 0} onClick={onGenerate}>Generate</Generate>
            </GenerateContainer>
        </Container>
    );
}

export default SeedKeywords;