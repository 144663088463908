import React, { useEffect } from "react";
// import { useAdManagement } from "../context/AdManagementContext";
// import GeneratingPage from "./GeneratingPage";
// import ResultPage from "./ResultPage";
import { Route, Routes } from "react-router-dom";
import InputPages from "./InputPages";
import ResultPages from "./resultPage";
import { useAdIntegrationInfo } from "../hooks/useAdManagementInfo";
import { useAdIntegration } from "../context/AdManagementContext";
import GlobalLoader from "../../../../HOC/GlobalLoader";
import { LoaderWrap } from "./index.styled";
// import SelectAdDetails from './SelectAdAccount'
// import SelectPlatform from './InputPages/SelectPlatform'
// import { useEffect } from "react";
// import { useEffect } from "react";

const Root = () => {
  const {
    newAdManagementState: { connectedAdAccount },
    newAdManagementDispatch,
  } = useAdIntegration();

  const adManagementHook = useAdIntegrationInfo();

  const renderingComponent = () => {
    if (!adManagementHook.isGetConnectedListLoading && connectedAdAccount) {
      if (connectedAdAccount.length == 0) {
        return <InputPages />;
      } else {
        return <ResultPages />;
      }
    } else {
      return (
        <LoaderWrap>
          <GlobalLoader />
        </LoaderWrap>
      );
    }

    // if (connectedAdAccount) return <ResultPages />;
    // if(adManagementState.isAdAccount) return <SelectAdDetails />
  };

  useEffect(() => {
    adManagementHook.setGetConnectedList();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={renderingComponent()} />
        {/* <Route path="result" element={<ResultPage />} /> */}
      </Routes>
    </>
  );
};

export default Root;
