import GoogleAdsImg from "../../../../media/Dashboard/GoogleAds.svg";
import facebookAdsImg from "../../../../media/Dashboard/facebookNew.svg";
import linkedinAdsImg from "../../../../media/Dashboard/linkedin.svg";
import amazonAdsImg from "../../../../media/Dashboard/amazon.svg";
import AdspyderNav from "../../../../media/Dashboard/AdspyderNav.svg";

const adResultData = {
  platformArray: [
    {
      platform: GoogleAdsImg,
      title: "Google ad account",
      connected: true,
      description:
        "Connect your Google Ads account to unlock in-depth competitor insights with AdSpyder",
    },
    {
      platform: facebookAdsImg,
      title: "Facebook ad account",
      connected: false,
      description:
        "Link your Facebook Ads account for enhanced campaign analysis with AdSpyder.",
    },
    {
      platform: linkedinAdsImg,
      title: "LinkedIn ad account",
      connected: false,
      description:
        "Connect LinkedIn Ads for advanced B2B ad monitoring with AdSpyder.",
    },
    {
      platform: amazonAdsImg,
      title: "Amazon ad account",
      connected: false,
      description:
        "Connect your Amazon Ads account to discover powerful insights",
    },
  ],
  metricDetails: [
    { title: "Return on Ad Spend (ROAS)", spend: "4.01x" },
    { title: "Cost-per-click (CPC)", spend: "$0.9" },
    { title: "Conversion", spend: "2.50%" },
  ],
  AdAccountDummy: [
    { account: "Google ads account 1", id: "123-456-7890", connected: false },
    { account: "Flonnect ad account ", id: "123-456-7890", connected: true },
    { account: "Google ad acc", id: "123-456-7890", connected: false },
    { account: "Google ads account 2", id: "123-456-7890", connected: false },
    { account: "Google ads account 3", id: "123-456-7890", connected: false },
    { account: "Google ads account 4", id: "123-456-7890", connected: false },
    { account: "Google ads account 5", id: "123-456-7890", connected: false },
    { account: "Google ads account 6", id: "123-456-7890", connected: true },
    { account: "Google ads account 7", id: "123-456-7890", connected: false },
    { account: "Flonnect ad account 2", id: "123-456-7890", connected: false },
    { account: "Google ads account 1", id: "123-456-7890", connected: false },
    { account: "Google ads account 1", id: "123-456-7890", connected: false },
    { account: "Flonnect ad", id: "123-456-7890", connected: false },
    { account: "Google ads account 1", id: "123-456-7890", connected: false },
    { account: "Flonnect ad", id: "123-456-7890", connected: false },
  ],
  tabOptions: ["Impressions", "Clicks", "CTR", "Total Spent"],
  dummyData: [
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "500",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "200",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "500",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "200",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "500",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "200",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
  ],
  detailsOption: [
    { option: "Visits" },
    { option: "Clicks" },
    { option: "Conversions" },
    { option: "Cost" },
    { option: "Profit" },
    { option: "Impression" },
    { option: "ROI" },
  ],
  tableDetails: [
    {
      Campaign: [
        {
          status: true,
          description:
            "Sale | Search | Masterchow Store | 12-5-22Sale | Search | Masterchow Store ",
          profits: 10000,
        },
        {
          status: false,
          description:
            "Amazon Deals Page | Sales | Display | 13-10-2022Sale | Search | Masterchow Store ",
          profits: 300,
        },
        {
          status: true,
          description:
            "Sale | Search | Masterchow Store | 12-5-22Sale | Search | Masterchow Store ",
          profits: 4000,
        },
        {
          status: true,
          description: "Sale | Search | Masterchow Store | 12-5-22",
          profits: 2100,
        },
        {
          status: true,
          description: "Sale | Search | Masterchow Store | 12-5-22",
          profits: 2100,
        },
      ],
    },
    {
      "Traffic Source": [
        {
          status: true,
          description: "Google ads",
          profits: 10000,
        },
        {
          status: false,
          description: "Facebook ads",
          profits: 300,
        },
        {
          status: true,
          description: "Tik Tok",
          profits: 4000,
        },
        {
          status: true,
          description: "Linkedin",
          profits: 2100,
        },
        {
          status: true,
          description: "Reddit",
          profits: 2000,
        },
      ],
    },
    {
      Audience: [
        {
          status: true,
          description: "Female",
          profits: 10000,
        },
        {
          status: false,
          description: "Young Adults (20-29 years)",
          profits: 300,
        },
        {
          status: true,
          description: "Adults (30-39 years)",
          profits: 4000,
        },
        {
          status: true,
          description: "Middle-aged Adults (40-54 years)",
          profits: 2100,
        },
        {
          status: true,
          description: "Top 20% Household income",
          profits: 2000,
        },
      ],
    },
    {
      Countries: [
        {
          status: true,
          description: "India",
          profits: 10000,
        },
        {
          status: false,
          description: "United States",
          profits: 300,
        },
        {
          status: true,
          description: "Singapore",
          profits: 4000,
        },
        {
          status: true,
          description: "Dubai",
          profits: 2100,
        },
        {
          status: true,
          description: "London",
          profits: 2000,
        },
      ],
    },
  ],
};
export default adResultData;
