import { useState } from "react";
import { appClient } from "../../../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAdGenerator } from "../context/adGeneration";
import { useAuth } from "../../../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";

const createCampaign = (data) => {
  const config = {
    url: `/new-image-ad/create-campaign`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const processedState = (data) => {
  const config = {
    url: `/new-image-ad/get-processed-state`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const updateCampaign = (data) => {
  const config = {
    url: `/new-image-ad/update-campaign-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};

export const useImageAdGenInfo = () => {
  const navigate = useNavigate();
  const { adGeneratorState, adGeneratorDispatch } = useAdGenerator();
  const location = useLocation();

  // const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator();
  //   const [wesbiteMetaLoading, setWesbiteMetaLoading] = useState(false);

  const {
    name,
    description,
    productDetails,
    colors,
    images,
    logo,
    stockImages,
  } = adGeneratorState.brandDetails;

  const { authState } = useAuth();

  const { mutate: getCreateCampaign, isLoading: isCreateCampaignLoading } =
    useMutation(createCampaign, {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_GENERATED_AD_ID",
          value: data.campaignId,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });
  const { mutate: getProcessedState, isLoading: isProcessedStateLoading } =
    useMutation(processedState, {
      onSuccess: (data) => {
        if (!data.processed) {
          setTimeout(() => {
            getProcessedState({
              campaignId: adGeneratorState.generatedAdId,
              projectId: authState.defaultProjectInfo.projectId,
            });
          }, 2000);
        } else {
          adGeneratorDispatch({
            type: "SET_WEBSITE_META_LOADING",
            value: false,
          });
          setTimeout(() => {
            if (!name) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                },
              });
            }
          }, 500);

          setTimeout(() => {
            if (!description) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutTheBrand
                    ? data.brandDetails.aboutTheBrand
                    : "",
                },
              });
            }
          }, 1000);

          setTimeout(() => {
            if (!productDetails) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutTheBrand
                    ? data.brandDetails.aboutTheBrand
                    : "",
                  productDetails: data.brandDetails.productDetails
                    ? data.brandDetails.productDetails
                    : "",
                },
              });
            }
          }, 1500);
          setTimeout(() => {
            if (colors.length === 0 || !logo || images.length === 0) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutTheBrand
                    ? data.brandDetails.aboutTheBrand
                    : "",
                  productDetails: data.brandDetails.productDetails
                    ? data.brandDetails.productDetails
                    : "",
                  colors: data.brandDetails.colors
                    ? data.brandDetails.colors.map((col) => col.color)
                    : [],
                  logo: data.brandDetails.logos
                    ? data.brandDetails.logos[0].logo
                    : [],
                  images: [
                    ...(data.brandDetails.brandImages
                      ? data.brandDetails.brandImages.map(
                          (brandImage) => brandImage.image
                        )
                      : []),
                    ...(data.brandDetails.stockImages
                      ? data.brandDetails.stockImages.map(
                          (stockImage) => stockImage
                        )
                      : []),
                  ],
                },
              });
            }
          }, 2000);
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const { mutate: getUpdateCampaign, isLoading: isUpdateCampaignLoading } =
    useMutation(updateCampaign, {
      onSuccess: (data) => {
        data.campaignId &&
          navigate(`/adgenerator-v3/image-ad/result?id=${data.campaignId}`);
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  return {
    getCreateCampaign,
    isCreateCampaignLoading,

    getProcessedState,
    isProcessedStateLoading,

    getUpdateCampaign,
    isUpdateCampaignLoading,
  };
};
