import styled from "styled-components";
import BgImage from "../../../../../media/Dashboard/Adgen-Home-Bg.svg"
import HistoryBgImage from '../../../../../media/Dashboard/history-bg.svg'

export const Wrapper = styled.div`
    width: 100%;
`;

export const CtaContainer = styled.div`
    background: url(${BgImage});
    height: 313px;
    background-size: cover;
    padding: 40px 50px;
`;

export const PersonaListContainer = styled.div`
    padding: 40px 48px;
    height: 313px;
    h2{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
    }
`;

export const HistoryContainer = styled.div`
    padding: 40px 48px;
    h2{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
    }
`;

export const LeftCtaContent = styled.div`
    width: 50%;
    h1{
        margin: 0px;
        color: #1C1C1C;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 34px;
    }
    p{
        color: #1C1C1C;
        font-size: 16px;
        font-weight: 400;
    }
`;

export const RightCtaContent = styled.div`
    width: 50%;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    div p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1C1C1C;
        text-align: center;
        margin-top: 12px;
    }
`;


export const PlatformList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 22px;
    width: 325px;
`;


export const PlatformContainer = styled.div`
    width: 55px;
    height: 55px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px #E7E7E7;
    span{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #000;
    }
`;

export const GenerateButton = styled.div`
    border-radius: 11px;
    border: 1px #CFCFCF;
    background: linear-gradient(180deg, #6907ba, #D057C6);
    height: 130px;
    width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
        margin-bottom: 6px;
    }
`;

export const GenerateFromLinkButton = styled.div`
    border-radius: 11px;
    border: 1px #CFCFCF;
    background: #1C1C1C;
    height: 130px;
    width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const DraftButton = styled.div`
    border-radius: 11px;
    border: 1px #CFCFCF;
    background: #1C1C1C;
    height: 130px;
    width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const HistoryList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 20px;
`;

export const HistoryCard = styled.div`
    width: ${props => (props.adType == "imagead") ? "200px" : "333px"};
    height: 197px;
    border: 1px #ECECEC;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 1%) 0px 0px 24px 0px;
`;

export const ImageContainer = styled.div`
    background: ${props => props.adType == "imagead" ? `url(${HistoryBgImage})` : "rgba(221, 221, 221, 0.50)"};
    height: 140px;
    width: 100%;
    display: flex;
    align-items: ${props => props.adType == "imagead" ? "flex-end" : "center"};
    justify-content: center;

    border-radius: 8px 8px 0px 0px;
`;

export const HistoryCardContent = styled.div`
    width: 100%;
    height: 57px;
    border-radius: 0px 0px 8px 8px;
    background: #FFF;
    padding: 12px;
    flex-direction: row;
    display: flex;
`;

export const MaxSizeWrapper = styled.div`
    width: 1436px;
    height: 100%;
    max-width: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
`;

export const HistoryCardLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(100% - 30px);
`;

export const DateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    span{
        font-size: 10px;
        font-weight: 400;
        color: #9C9C9C;
    }
    img{
        height: 12px;
    }
`;

export const NameContainer = styled.div`
        font-size: 12px;
    font-weight: 400;
    color: #1C1C1C;
`;

export const HistoryCardRight = styled.div`
    
`;

export const EditNameContainer = styled.div`
    height: 24px;
    width: 24px;
    background: #1C1C1C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    img{
        width: 12px;
    }
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 285px;
    padding: 9px;
    border-radius: 9.57px;
    background: #FFF;
`

export const Header = styled.div`
display: flex;
column-gap: 8px;
`
export const LogoWrapper = styled.div`
border-radius: 16px;
    border: 1px solid rgb(236, 236, 236);
    background: rgb(241, 243, 244);
    display: flex;
    padding: 6px;
    width: 24px;
    height: 24px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
img{
    height: 15px;
}
`
export const TitleWrap = styled.div`
display: flex;
flex-direction: column;
row-gap: 2px;
width: 65%;
`
export const Title = styled.div`
overflow: hidden;
color: #2E2E2E;
text-overflow: ellipsis;
font-family: Inter;
font-size: 10px;
font-weight: 400;
`
export const LinkWrap = styled.div`
overflow: hidden;
color: #598763;
font-family: Inter;
font-size: 8px;
font-weight: 300;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`
export const KeywordWrap = styled.div`
overflow: hidden;
color: #3F33C8;
font-family: Inter;
font-size: 12px;
font-weight: 400;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
`
export const DescriptionWrap = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
font-family: Inter;
font-size: 10px;
font-weight: 400;
line-height: 14px; /* 142.857% */
height: 30px;

`

export const FooterWrap = styled.div`
display: flex;
justify-content: space-between;
padding-top: 13px;
align-items: center;
`
export const CountryWrap = styled.div`
display: flex;
column-gap: 12px;
align-items: center;
`
export const Country = styled.div`
overflow: hidden;
color: rgba(28, 28, 28, 0.50);
text-overflow: ellipsis;
white-space: nowrap;
font-family: Inter;
font-size: 14px;
font-weight: 400;
`
export const GoogleIcon = styled.div`
img{
    height: 16px;
}
`
