import styled from "styled-components";

export const Wrapper = styled.div`
    
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
`;

export const HeaderLeft = styled.div`
        display: flex;
        align-items: center;
`;

export const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    span{
        font-size: 14px;
        font-weight: 300;
        font-style: italic;
        color: #1C1C1C
    }
    button{
        border-radius: 6px;
        background: #FF711E;
        outline: none;
        border: none;
        color: #FFF;
        width: 144px;
        height: 44px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
`;

export const ContentContainer = styled.div`
    margin-top: 10px;
`;

export const GeneratingUi = styled.div`
    height: 75px;
    display: flex;
    border-radius: var(--8, 8px);
    background: rgba(255, 255, 255, 0.50);
    gap: 16px;
    margin-bottom: 5px;
    align-items: center;
    img{
        height: 70px;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
        line-height: 20px;
    }
`;

export const ResultsContainer = styled.div`
    
`;

export const CardsRow = styled.div`
    
`;

export const AdCard = styled.div`
    cursor: pointer;
`;

export const CardRowHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    p{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
    }
`;

export const TypeOfAd = styled.div`

`;

export const CardRowContent = styled.div`
    display: flex;
    gap: 16px;
`;

export const TemplateDetailsContainer = styled.div`
    position: absolute;
    background: #0000005e;
    height: calc(100vh - 65px);
    top: 65px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
`;

export const TemplateDetailsWrapper = styled.div`
    max-width: 400px;
    background: #FFF;
    height: 100%;
    padding: 20px;
`;

export const TemplateDetailsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h2{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
    }
    img{
        height: 24px;
        cursor: pointer;
    }
`;

export const TemplateDetailsContent = styled.div`
    img{
        height: 128px;
            margin-bottom: 50px;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: #1C1C1C;
        line-height: 150%;
    }
`;

export const EditTemplateButton = styled.button`
    border-radius: 7px;
    background: #FF711E;
    width: 100%;
    height: 50px;
    color: #FFF;
    border: none;
    outline: none;
    margin-top: 30px;
`;
