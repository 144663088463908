import { appClient } from "../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAdIntegration } from "../context/AdManagementContext";
import { getSearchParams } from "../../../../../utils/helper";
import { useLocation } from "react-router-dom";

const getAllConnected = async (data) => {
  const config = {
    url: "/admanagement/get-connected-accounts",
    method: "GET",
  };

  return appClient(config);
};
const getRecentConnection = async (data) => {
  const config = {
    url: "/admanagement/get-recent-connection",
    method: "GET",
  };

  return appClient(config);
};
const getListAdAccounts = async (data) => {
  const config = {
    url: "/admanagement/list-adaccounts",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getAccountConnected = async (data) => {
  const config = {
    url: "/admanagement/connect-account",
    method: "POST",
    data,
  };

  return appClient(config);
};

export const useAdIntegrationInfo = () => {
  const { newAdManagementState, newAdManagementDispatch } = useAdIntegration();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: setGetConnectedList, isLoading: isGetConnectedListLoading } =
    useMutation(getAllConnected, {
      onSuccess: (data) => {
        // adManagementDispatch({ type: "SET_AD_ACCOUNT_LIST", value: data });
        if (data.length > 0) {
          newAdManagementDispatch({ type: "SET_CONNECTED_DATA", value: data });
          //   adManagementDispatch({ type: "SET_AD_DATA_PAGE", value: true });
        } else {
          newAdManagementDispatch({ type: "SET_CONNECTED_DATA", value: [] });
        }
      },
      onError: (err) => {},
    });

  const { mutate: setRecentConnection, isLoading: isRecentConnectionLoading } =
    useMutation(getRecentConnection, {
      onSuccess: (data) => {
        newAdManagementDispatch({
          type: "SET_INTEGRATION_ID",
          value: data.integrationId,
        });
        setListAdAccounts({ integrationId: data.integrationId });
      },
      onError: (err) => {},
    });
  const { mutate: setListAdAccounts, isLoading: isListAdAccountsLoading } =
    useMutation(getListAdAccounts, {
      onSuccess: (data) => {
        newAdManagementDispatch({ type: "SET_LIST_AD_ACCOUNTS", value: data });
      },
      onError: (err) => {},
    });
  const { mutate: setAccountConnected, isLoading: isAccountConnectedLoading } =
    useMutation(getAccountConnected, {
      onSuccess: (data) => {
        setGetConnectedList();
        const searchParamFromState = getSearchParams(location.search);
        const { id } = searchParamFromState;
        if (id) {
          // navigate(`/admanagement/ad-campaigns/new-campaign?id=${id}`);
        } else {
          // adManagementDispatch({ type: 'SET_SELECT_AD_ACCOUNT', value: false });
          // adManagementDispatch({ type: 'SET_INPUT_PAGE', value: true });
          // adManagementDispatch({ type: 'SET_AD_DATA_PAGE', value: true });
        }
      },
      onError: (err) => {
        if (err.response.data.errors[0].message == "check email") {
          // newAdManagementDispatch({ type: "SET_CHECK_EMAIL_POPUP", value: true });

          console.log(
            err.response.data.errors[0].message,
            "err.response.data.errors[0].message"
          );
        }
      },
    });

  return {
    setGetConnectedList,
    isGetConnectedListLoading,
    setRecentConnection,
    isRecentConnectionLoading,
    setListAdAccounts,
    isListAdAccountsLoading,
    setAccountConnected,
    isAccountConnectedLoading,
  };
};
