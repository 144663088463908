import React, { useState } from "react";
import {
  BrandWrap,
  CardHeadWrap,
  CardWrapper,
  Description,
  Divider,
  LogoWrap,
  Platform,
  PlatformWrap,
  PreviewText,
  PublishAdContent,
  PublishButton,
  PublishContainer,
  TargetAmount,
  TargetAudience,
  TargetWrap,
  Title,
  ViewOption,
  ViewOptionWrap,
  ViewWrap,
} from "./index.styled";
import downArrow from "../../../../../../../../../../media/Dashboard/down-arrow-grey.svg";
import platformImg from "../../../../../../../../../../media/Dashboard/google.svg";
import tablet from "../../../../../../../../../../media/Dashboard/Tablet-Telephone.svg";
import desktopImg from "../../../../../../../../../../media/Dashboard/desktop.svg";

const PreviewContent = () => {
  const [publishToggle, setPublishToggle] = useState(true);

  const renderViewCard = () => {
    return (
      <CardWrapper>
        <CardHeadWrap>
          <LogoWrap>
            <img src={platformImg} />
          </LogoWrap>
          <BrandWrap>
            <h4>Apple</h4>
            <p>https://www.apple.com/in</p>
          </BrandWrap>
        </CardHeadWrap>
        <Title>iPhone - Apple official Site</Title>
        <Description>
          The all-new iPhone 14 Pro and iPhone 14. Buy now. Your photo. Your
          font. Your widgets. Your iPhone. Accessibility features. All-day
          battery life. iOS 16. Ceramic Shield. Services. No-contact free
          delivery, EMI available, Shop with Specialists.
        </Description>
      </CardWrapper>
    );
  };

  return (
    <PublishAdContent>
      <h3>Publish ad group 1</h3>
      <PlatformWrap>
        <Platform>
          <img src={platformImg} />
          <p>Google</p>
        </Platform>
        <img
          src={downArrow}
          onClick={() => setPublishToggle(!publishToggle)}
          props={publishToggle}
        />
      </PlatformWrap>
      {publishToggle && (
        <>
          <PublishContainer>
            <ViewWrap>
              <ViewOptionWrap>
                <ViewOption>
                  <img src={tablet} />
                </ViewOption>
                <ViewOption>
                  <img src={desktopImg} />
                </ViewOption>
              </ViewOptionWrap>
              {renderViewCard()}
            </ViewWrap>
            <PreviewText>
              This preview shows potential ads assembled using your assets. Not
              all combinations are shown. Assets can be shown in any order so
              make sure that they make sense individually or in combination and
              don't violate our policies or local law. Some shortening may also
              occur in some formats. You can make sure certain text appears in
              your ad. Learn more
            </PreviewText>
          </PublishContainer>
          <TargetWrap>
            <TargetAudience>Estimated target audience</TargetAudience>
            <TargetAmount>2,458,759</TargetAmount>
            <Divider></Divider>
            <TargetAudience>Recommended budget</TargetAudience>
            <TargetAmount>
              <span>$5.00 - $25.00</span> / per day
            </TargetAmount>
          </TargetWrap>
          <PublishButton>Publish</PublishButton>
        </>
      )}
    </PublishAdContent>
  );
};

export default PreviewContent;
