import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
`;

export const Label = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 16px;
display: flex;
gap: 16px;
align-items: center;
`;

export const SelectingPanel = styled.div`
display: flex;
gap: 8px;
width: 100%;
`;

export const Panel = styled.div`
width: 49.6%;
`;

export const Window = styled.div`
    width: 100%;
    height: 380px;
    flex-shrink: 0;
    border-radius: var(--8,8px);
    border: 1px #DADFE4;    
    background: ${(props) => props.recommendation ? "#F5F5F5" : "#FAF8FF"};
    display: flex;
    align-items: center;
    padding: ${(props) => props.recommendation ? "unset" : "24px"};
`;


export const ChoicesList = styled.div`
    width: 98.588709677%;
    height: 348px;
    flex-shrink: 0;
    border-radius: var(--8,8px);
    background: transparent;
    padding: 0px 12px 0px 24px;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;

    :overflow-bottom{
        background: red;
    }
`;

export const ChoiceContainer = styled.div`
    width: 100%;
    height: 70px;
    flex-shrink: 0;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    padding: 14px 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: pointer;
`;

export const Choice = styled.div`
color: ${(props) => props.isSelected ? "#0070FF" : "#4F4F4F"};
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const CheckBox = styled.div`
width: 22px;
height: 22px;
flex-shrink: 0;
border-radius: 4px;
border: ${(props) => props.isChecked ? "none" : "1px solid #D0D4D9"};
background: ${(props) => !props.isChecked ? "#FFF" : "#0070FF"};
display: flex;
align-items: center;
justify-content: center;
`;

export const SelectedPanel = styled.div`
width: 100%;
height: 100%;
border-radius: 4px;
background: #FFF;
padding: 24px;
`;

export const Input = styled.input`
border: none;
outline: none;
color: #1c1c1c;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 26px;
width: 100%;

::placeholder{
    color: rgba(28, 28, 28, 0.40);
}
`;

export const SelectedKeywords = styled.div`
display: flex;
gap: 8px;
flex-wrap: wrap;
`;

export const KeywordContainer = styled.div`
display: inline-flex;
height: 29.07px;
padding: 3.876px 8px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
border-radius: 5.814px;
background: ${(props) => props.odd ? "#FBE7E9" : "#EDE7FB"};
`;


export const Image = styled.img`
cursor: ${(props) => props.button ? "pointer" : "unset"};
`;

export const GenerateContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

export const Generate = styled.button`
display: inline-flex;
padding: 16px 123px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 6px;
background: linear-gradient(180deg, #6907BA 0%, #D057C6 83.33%);
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
cursor: pointer;
opacity: ${props => props.isDisabled ? "0.3": "1"};
pointer-events: ${props => props.isDisabled ? "none": "all"};
outline: none;
border: none;
`;