import React, { useState } from "react";
import DownArrow from "../../../../../../../../../../media/Dashboard/uparrow.png"
import FacebookIcon from "../../../../../../../../../../media/Dashboard/facebook.svg"
import LinkedinIcon from "../../../../../../../../../../media/Dashboard/linkedin.svg"
import { Wrapper, SelectedAnimateDropdown, SelectedAnimationContainer, DropDown, DropDownOption, DropdownWrapper } from "./index.styled"


const Animate = () => {
    const [selectedAnimation, setSelectedAnimation] = useState({
        0: "none",
        1: "none",
        2: "none",
        3: "none"
    });
    const [openedDropdown, setOpenedDropdown] = useState(null);

    const animateData = [
        {
            name: "text 1",
        }, {
            name: "text 2",
        }, {
            name: "text 3",
        }, {
            name: "Image 1",
        }
    ];

    const AnimationList = [
        "none",
        "Fade In",
        "Fade Out",
        "Slide In",
        "Slide Out"
    ]

    const onOptionClick = (animation, name) => {
        let tempObj = {}
        tempObj[name] = animation;
        setSelectedAnimation({ ...selectedAnimation, ...tempObj })
        setOpenedDropdown(null)
    }

    return <Wrapper>
        {
            animateData.map((data, id) => {
                return <DropdownWrapper>
                    <h2>{data.name}</h2>
                    <SelectedAnimateDropdown>
                        <SelectedAnimationContainer dropDownOpen={openedDropdown == data.name} onClick={() => {
                            if(openedDropdown == data.name){
                                setOpenedDropdown(null)
                            }else{
                                setOpenedDropdown(data.name)
                            }
                        }}>
                            <div>
                                <p>{selectedAnimation[id]}</p>
                            </div>
                            <img src={DownArrow} />
                        </SelectedAnimationContainer>
                        {(openedDropdown == data.name) && <DropDown>
                            {
                                AnimationList.map((animation) => {
                                    return <DropDownOption onClick={() =>
                                        onOptionClick(animation, id)
                                    }
                                    >
                                        {animation}
                                    </DropDownOption>
                                })
                            }
                        </DropDown>}
                    </SelectedAnimateDropdown>
                </DropdownWrapper>
            })
        }
    </Wrapper>
}

export default Animate