import styled from "styled-components";

export const KeywordsWrapper = styled.div`
  border: 1px solid #e3e3e3;
  background: #fff;
  height: auto;
  padding: 24px 26px 45px 32px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  h2 {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const DeleteButton = styled.button`
  width: 104px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #111926;
  border-radius: 8px;
  background: #fff;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;

export const AddNewButton = styled.button`
  width: 184px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  border-radius: 8px;
  background: #111926;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const TableWrap = styled.div``;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(234, 236, 240);
  background: rgb(249, 249, 249);
  > div {
    padding: 12px 24px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #667085;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
`;
export const KeyHeader = styled.div`
  cursor: pointer;
  display: flex;

  input {
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
`;
export const KeywordHeader = styled.div``;
export const CampaignCountHeader = styled.div``;
export const AdsCountHeader = styled.div``;
export const AvgMonthlySearchHeader = styled.div``;
export const QuaterlyChangesHeader = styled.div``;
export const AvgCpcHeader = styled.div``;
export const TrendHeader = styled.div``;
export const TableBody = styled.div`
  overflow-y: auto;
  min-height: 265px;
  max-height: 500px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  border-bottom: 1px solid #eaecf0;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #667085;
  .table-header {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  .no-change {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #364254;
    border-radius: 16px;
    background: #f2f4f7;
    padding: 2px 8px;
  }

  .domainName img {
    /* width: 25px;
    height: 25px; */
    border-radius: 50%;
  }
  .domainName p {
    display: flex;
    gap: 8px;
    font-weight: 500;
    align-items: center;
    color: #101828;
  }
  .platformTargeted div {
    max-width: 100%; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .cta span {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #364254;
    border-radius: 16px;
    background: #f2f4f7;
    padding: 2px 8px;
    cursor: pointer;
  }
  .platformTargeted img {
    height: 20px;
    margin-right: 8px;
  }
  .keyword {
    color: #101828;
    font-weight: 500;
  }

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    background: #fff;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
    border: solid 1px #d0d5dd;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #0070ff;
    border: solid 1px #0070ff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border-style: solid;
    border-color: white;
    border-image: initial;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
  z-index: 999;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the popup */
  width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* Above the overlay */
  width: 30%;
  min-height: 240px;
`;
export const ModalClose = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    color: #4f4f4f;
    font-size: 18px;
    font-weight: 600;
  }
  img {
    height: 24px;
    cursor: pointer;
  }
`;
export const Platform = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: #101828;

  font-weight: 500;
`;
export const PlatformsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 24px;
  justify-content: center;
  p {
    background: #ede7fb;
    padding: 6px 11px;
    border-radius: 5.814px;
    display: flex;
    align-items: center;
    color: var(--Layout-800, #222);
    text-transform: capitalize;
    font-size: 14px;
    column-gap: 10px;
    cursor: pointer;
    img {
      height: 21px;
    }
  }
`;
