import styled from "styled-components";

export const SeedKeywordWrap = styled.div`
  padding: 0px 18px 31px 12px;
`;
export const SeedKeywordContainer = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid #dadfe4;
  background: #faf8ff;
  height: 369px;
  padding: 22px 16px 11px 16px;
`;
export const SeedKeywordDiv = styled.div`
  border-radius: 4px;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 23px;
`;
export const SeedKeywordInput = styled.input`
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  height: 34px;
  border: unset;
  outline: none;
  &::placeholder{
    color: rgba(28, 28, 28, 0.4);

  }
`;
export const KeywordListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 8px;
  margin-top: 15px;
`;
export const SeedKey = styled.div`
  border-radius: 5.814px;
  background: ${(prop) => (prop.colorProp % 2 == 0 ? "#FBE7E9" : "#EDE7FB")};
  padding: 3.876px 8px;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 4px;
  img {
    height: 14px;
    cursor: pointer;
  }
`;
