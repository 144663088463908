import { useEffect, useRef, useState } from "react";
import { countriesList } from "../../../../../../../../utils/countryData";
import languages from "../../../../../../../../utils/languages";
import statics from "../../../../../statics";
import {
  DropdownContainer,
  Input,
  Item,
  Label,
  List,
  SearchBar,
} from "./index.styled";
import { useAdGenerator } from "../../../../../context/adGeneration";

function Dropdown({
  dropdownFor,
  onSelect,
  onDeselect,
  selectedItems,
  withoutSearchBar = false,
  filteringText,
}) {
  const { steps } = statics;
  // const { occupations } = steps[4]
  const [list, setList] = useState();
  const [filteredList, setFilteredList] = useState();
  const searchBarRef = useRef(null);
  const checkBoxesRefs = useRef([]);
  const {
    adGeneratorState: { occupationItem },
    adGeneratorDispatch,
  } = useAdGenerator();

  const filterList = () => {
    setFilteredList(
      list.filter((item) =>
        item.toLowerCase().includes(searchBarRef.current.value.toLowerCase())
      )
    );
  };

  const handleChange = (e, item) => {
    if (e.target.checked) onSelect(item);
    else onDeselect(item);
  };

  const handleClickOnLabel = (item, index) => {
    const target = checkBoxesRefs.current[index];
    if (!target.checked) {
      target.checked = true;
      onSelect(item);
    } else {
      target.checked = false;
      onDeselect(item);
    }
  };

  const renderList = (listOfItems) => {
    return listOfItems?.map((item, index) => {
      return (
        <Item
          key={index}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onBlur={(e) => e.stopPropagation()}
        >
          <Input
            type={"checkbox"}
            checked={selectedItems.includes(item)}
            onChange={(e) => handleChange(e, item)}
            ref={(el) => (checkBoxesRefs.current[index] = el)}
          />
          <Label onClick={() => handleClickOnLabel(item, index)}>{item}</Label>
        </Item>
      );
    });
  };

  const filterListByText = () => {
    if (filteringText && list) {
      setFilteredList(
        list.filter((item) =>
          item.toLowerCase().includes(filteringText.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    switch (dropdownFor) {
      case "language":
        setList(languages.map((item) => item.name));
        break;

      case "occupation":
        setList(occupationItem);
        break;

      case "country":
        setList(countriesList.map((country) => country.name.common));
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    filterListByText();
  }, [filteringText]);

  return (
    <DropdownContainer>
      {!withoutSearchBar && (
        <SearchBar
          ref={searchBarRef}
          type={"text"}
          placeholder={`Search ${dropdownFor}...`}
          onChange={() => {
            filterList();
          }}
          onClick={(e) => {
            console.log("onclick", e);
            e.stopPropagation();
          }}
          onBlur={(e) => e.stopPropagation()}
        />
      )}
      <List withoutSearchBar={withoutSearchBar}>
        {!filteredList && list && renderList(list)}
        {filteredList && renderList(filteredList)}
      </List>
    </DropdownContainer>
  );
}

export default Dropdown;
