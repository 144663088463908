import React, { useState } from "react";
import {
  ChartHeadWrap,
  GraphContainer,
  PlatformDiv,
  PlatformName,
  PlatformchartWrap,
  TabDiv,
  TabOptions,
} from "./index.styled";
import adResultData from "../../../../../static";
import { Line } from "react-chartjs-2";

const AccountDetailsGraph = () => {
  const { tabOptions } = adResultData;
  const [tabToggle, setTabToggle] = useState(0);

  const data = () => {
    return {
      labels: [
        "Jul 13",
        "Jul 14",
        "Jul 15",
        "Jul 16",
        "Jul 17",
        "Jul 18",
        "Jul 19",
        "Jul 20",
        "Jul 21",
        "Jul 22",
        "Jul 23",
        "Jul 24",
        "Jul 25",
        "Jul 26",
        "Jul 27",
        "Jul 28",
      ],
      datasets: [
        {
          label: "",
          data: [3, 2.6, 3, 2, 5, 5.2, 4, 3, 2, 3, 5.3, 2.6, 3, 2, 3, 5],
          fill: "start",
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(
              0,
              0,
              0,
              ctx.canvas.height
            );
            gradient.addColorStop(0, "rgba(37, 205, 37, 0.40)");
            gradient.addColorStop(0.9959, "rgba(37, 205, 37, 0)");
            return gradient;
          },

          borderColor: "#25CD25",
          borderWidth: 1,
          pointBackgroundColor: "#fff",
          lineTension: 0.4,
        },
        {
          label: "",
          data: [4, 4.2, 4, 3, 4, 3.6, 4, 4, 3, 3.4, 3, 3.7, 4, 3, 3.4, 3.5],
          fill: "start",
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(
              0,
              ctx.canvas.height,
              0,
              0
            );

            gradient.addColorStop(0.0522, "rgba(232, 230, 249, 0.00)"); // 5.22%
            gradient.addColorStop(0.9675, "rgba(232, 230, 249, 0.80)"); // 96.75%

            return gradient;
          },
          lineTension: 0.4,
          pointBackgroundColor: "#fff",
          borderColor: "#1F4DDA",
          borderWidth: 1,
        },
      ],
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Inter",
            size: 12,
            weight: 400,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          tickColor: "#fff",
        },
        ticks: {
          font: {
            family: "Inter",
            size: 12,
            weight: 400,
          },
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        // hoverRadius: 8,
      },
    },

    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <PlatformchartWrap>
      <ChartHeadWrap>
        <TabOptions>
          {tabOptions.map((item, index) => (
            <TabDiv
              onClick={() => setTabToggle(index)}
              isActive={tabToggle == index ? true : false}
            >
              {item}
            </TabDiv>
          ))}
        </TabOptions>
        <PlatformDiv>
          <PlatformName>
            <p></p>Google
          </PlatformName>
          <PlatformName>
            <p></p>Facebook
          </PlatformName>
        </PlatformDiv>
      </ChartHeadWrap>
      <GraphContainer>
        <Line data={data()} options={options} />
      </GraphContainer>
    </PlatformchartWrap>
  );
};

export default AccountDetailsGraph;
