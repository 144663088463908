import React, { useState, useRef, useEffect } from 'react'
import { useMutation } from "@tanstack/react-query"
import toast from "react-hot-toast"
import { appClient } from '../../../../utils/axios-utils'
import { useNavigate, useLocation } from 'react-router-dom'

// Styles
import { HeaderContainer, Logo, NavContainer, LinksContainer, NavButton, DropDown, ButtonContent, DropDownContent, DisabledNavButton, RightHeader, BreadcrumbContainer, FeedbackContainer, SelectProject, CurrentSelectedProject, ProjectListDropDown, Project } from "./index.styled"

// Images
import AdspyderBlackLogo from "./../../../../media/Images/AdspyderBlackLogo.svg"
import ProfileLogo from "./../../../../media/Images/personicon.svg"
import UpArrow from "../../../../media/Dashboard/uparrow.png"
import DownArrow from "../../../../media/Dashboard/downarrow.png"
import KeywordGreyLogo from "../../../../media/Dashboard/KeywordGreyLogo.svg"
import KeywordOrangeLogo from "../../../../media/Dashboard/KeywordOrangeLogo.svg"
import AdLibGreyLogo from "../../../../media/Dashboard/AdLibGreyLogo.svg"
import AdLibOrangeLogo from "../../../../media/Dashboard/AdLibOrangeLogo.svg"
import GlobeGreyLogo from "../../../../media/Dashboard/GlobeGreyLogo.svg"
import GlobeOrangeLogo from "../../../../media/Dashboard/GlobeOrangeLogo.svg"
import LandingPageGreyLogo from "../../../../media/Dashboard/LandingPageGreyLogo.svg"
import LandingPageOrangeLogo from "../../../../media/Dashboard/LandingPageOrangeLogo.svg"
import AiGreyLogo from "../../../../media/Dashboard/AiGreyLogo.svg"
import AiOrangeLogo from "../../../../media/Dashboard/AiOrangeLogo.svg"
import UserIcon from "../../../../media/Dashboard/UserIcon.png"
import { Link } from 'react-router-dom'
import { useAuth } from "../../../../context/auth"
import FeedbackIcon from "../../../../media/Dashboard/feedback.svg"
import FeedbackFocusIcon from "../../../../media/Dashboard/feedback-focus.svg"
import FeedbackIconWhite from "../../../../media/Dashboard/feedback-white.svg"
import Notification from '../Notification';
import { useOutsideAlerter } from '../../../../utils/outsiderAlert'
import ResultPageSearch from '../ResultPageSearch'
import { useGetUserInfo } from '../../../hooks/useGetUserInfo'
import DownArrowIcon from "../../../../media/Dashboard/down-arrow-grey.svg"
// import { useDashboardInfo } from '../../../pages/Dashboard/hooks/useDashboardInfo'

const GeneralFeedback = () => {
    const config = {
        url: "/canny/login/redirect-url",
        method: "GET"
    }

    return appClient(config)
}

const logoutUser = () => {
    const config = {
        url: "/auth/logout",
        method: "DELETE"
    }

    return appClient(config)
}

const getProjectData = () => {
    const config = {
        url: `/dashboard-overview/competitors/get-projects`,
        method: "GET",
    };

    return appClient(config);
};

const pinProject = (data) => {
    const config = {
        url: `/dashboard-overview/client-profile/pin-project`,
        method: "POST",
        data
    }
    return appClient(config)
}

const breadcrumbList = {
    "adspy": {
        name: "Ad Library",
        logo: AdLibOrangeLogo
    },
    "keyword-analysis": {
        name: "Paid Keyword",
        logo: KeywordOrangeLogo
    },
    "keyword-funnel-analysis": {
        name: "Paid Keyword",
        logo: KeywordOrangeLogo
    },
    "url-analysis": {
        name: "Domain Overview",
        logo: GlobeOrangeLogo
    },
    "tracking": {
        name: "Domain Overview",
        logo: KeywordOrangeLogo
    },
    "landing-page-analysis": {
        name: "Landing Page",
        logo: LandingPageOrangeLogo
    },
    "adgenerator": {
        name: "Ad Generator",
        logo: AiOrangeLogo
    },
    "overview": {
        name: "",
        logo: ""
    },
    "projects": {
        name: "",
        logo: ""
    },
    "account": {
        name: "Account",
        logo: ""
    }

}

const Header = ({ noSideNav }) => {
    const location = useLocation();
    const segments = location.pathname.slice(1).split("/")
    const navigate = useNavigate()
    const ref = useRef()
    const [Over, setOver] = useState(false)
    const { authState, authDispatch } = useAuth()
    const [show, setShow] = useState(false)
    const { getCurrentUser } = useGetUserInfo()
    const [openProjectDropDown, setOpenProjectDropDown] = useState(false)
    // const useDashboardInfoHook = useDashboardInfo();

    let toastId = "";

    const { mutate: onLogout } = useMutation(logoutUser, {
        onSuccess: (data) => {
            authDispatch({ type: 'LOGOUT_SUCCESS' })
            toast.success("Signed out succesfully")
            authDispatch({ type: 'STOP_LOADER' })
            toast.dismiss(toastId)
            navigate('/')
        },
        onError: (err) => {
            toast.dismiss(toastId)
        },
        onMutate: () => {
            authDispatch({ type: 'START_LOADER' })
            // toastId = toast.loading("Signing out")
        }
    })

    const { mutate: GenerateFeedbackLinkMutate, isLoading: isFeedbackLinkLoading } = useMutation(GeneralFeedback, {
        onSuccess: (data) => {
            window.open(data.cannyRedirectFrontendUrl)
        },
        onError: (err) => {
            toast.dismiss(toastId)
        },
        onMutate: () => {

        }
    })

    const { mutate: getProjectDetails, isLoading: isProjectDetailsLoading } =
        useMutation(getProjectData, {
            onSuccess: (data) => {
                authDispatch({ type: "SET_PROJECT_LIST", value: data.projects });
            },
            onError: (err) => {
                console.log(err);
            },
            onMutate: () => { },
        });

    const { mutate: pinProjectMutate, isLoading: isLoadingPinProject } = useMutation(pinProject, {
        onSuccess: (data) => {
            console.log(data)
            getCurrentUser()
        },
        onError: (e) => console.log(e)
    })

    useOutsideAlerter(ref, setShow)

    const routesToNavigate = ["keyword-overview", "keyword-funnel", "keyword-tracking", "landing-page-analysis", "domain-overview", "domain-funnel", "domain-tracking", "adspy", "adgenerator", "text-ad", "image-ad"];

    const navigateToHomeRoute = (path) => {
        if (!routesToNavigate.includes(path)) return
        if (path === "text-ad" || path === "image-ad") {
            navigate(`/adgenerator/${path}`)
            return
        }
        if (path === "adgenerator") {
            navigate(`/adgenerator/${segments[1]}`)
            return
        }
        navigate(`/${path}`)
    }

    useEffect(() => {
        getProjectDetails()
    }, [])


    return (
        <HeaderContainer noSideNav={noSideNav} isNavCollapsed={authState.isNavCollapsed}>
            {noSideNav && <Link to={"/"}>
                <Logo>
                    <img src={AdspyderBlackLogo} />
                </Logo>
            </Link>}
            <NavContainer>
                {location.pathname.includes("/adspy/") ? <ResultPageSearch /> : <BreadcrumbContainer>
                    {segments.map((path, idx) => {
                        return <p className={idx > 0 && "sub-path"} onClick={() => {
                            navigateToHomeRoute(path)
                        }}>
                            {breadcrumbList[path] && <img src={breadcrumbList[path].logo} />}
                            <span>{(idx < segments.length) && idx != 0 && idx != segments.length ? "/  " : ""}{breadcrumbList[path] ? breadcrumbList[path].name : path} </span>
                        </p>
                    })}
                </BreadcrumbContainer>}

                <RightHeader>
                    {(authState.projects && authState.projects.length > 0) && <SelectProject>
                        <CurrentSelectedProject onClick={()=> setOpenProjectDropDown(!openProjectDropDown)}>
                            <div>
                                <img src={`https://www.google.com/s2/favicons?domain=${authState.defaultProjectInfo.website}`} />
                                <p>{authState.defaultProjectInfo.projectName}</p>
                            </div>
                            <img src={DownArrowIcon}/>
                        </CurrentSelectedProject>
                        {openProjectDropDown && <ProjectListDropDown>
                            {
                                authState.projects.map((project) => {
                                    return <Project 
                                    isDisabled={
                                        project.id == authState.selectedProject?.id
                                    }
                                    onClick={() => {
                                        authDispatch({type: "SET_SELECTED_PROJECT", value: project})
                                        pinProjectMutate({ projectId: project.id })
                                        setOpenProjectDropDown(false)
                                    }}>
                                        <img src={`https://www.google.com/s2/favicons?domain=${project.website}`} /><p>{project.projectName}</p>
                                    </Project>
                                })
                            }
                        </ProjectListDropDown>}
                    </SelectProject>}
                    <FeedbackContainer
                        onMouseOver={() => { setOver(true) }}
                        onMouseOut={() => { setOver(false) }}

                        onClick={() => {
                            GenerateFeedbackLinkMutate()
                        }
                        }
                    >
                        <img src={FeedbackIconWhite} /> <p>{Over}{isFeedbackLinkLoading ? "Loading.." : "feedback"}</p>
                    </FeedbackContainer>
                    <Notification />
                    <DropDown onClick={() => { console.log(show); setShow(!show) }}>
                        <ButtonContent>
                            <img src={authState?.profileImage ? authState.profileImage : UserIcon} />
                            {/* <img className='arrow-icon' src={
                            show ? UpArrow : DownArrow}
                            /> */}
                            {/* <span>{authState.username}</span> */}
                        </ButtonContent>
                        {show && <DropDownContent ref={ref}>
                            {authState?.plan?.status && <Link to={
                                "/account/profile"
                            } key={"account"}>
                                <span>
                                    Account & Settings
                                </span>
                            </Link>}
                            <Link to={
                                "/plans"
                            } key={"plans"}>
                                <span>
                                    Plans
                                </span>
                            </Link>
                            <a key={"logout"} onClick={onLogout}>
                                <span>
                                    Log out
                                </span>
                            </a>
                        </DropDownContent>}
                    </DropDown>
                </RightHeader>

            </NavContainer>


        </HeaderContainer>
    )
}

export default Header
