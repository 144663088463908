import React, { useEffect } from "react";
import {
  AdGroupContainer,
  AdGroupValue,
  AdGroupWrap,
  HighlightLine,
  NavAdDiv,
  NavWrap,
  ResultContainer,
  ResultWrapper,
} from "./index.styled";
import textGenStatic from "../static";
import { useTextAdGenerator } from "../../context/textAdGeneration";
import AdGroupInnerContent from "./components/AdGroupInnerContent";
import PublishAdInnerContent from "./components/PublishAdInnerContent";
import { useTextAdGenInfo } from "../../../hooks/useTextAdGenInfo";
import { getSearchParams } from "../../../../../../utils/helper";
import { useLocation } from "react-router-dom";
import { useAdGenerator } from "../../../context/adGeneration";

const TextAdGenerator = () => {
  // const {
  //   textAdGeneratorState: { adGroupItem },
  //   textAdGeneratorDispatch,
  // } = useTextAdGenerator();
  
  const { adGeneratorState: {isGenerationProcess, adGroupItem}, adGeneratorDispatch } = useAdGenerator()
  const { adGroupDetails } = textGenStatic;
  const {
    getGenerateProcess,
    getSingleAdGroupsData,
    isGenerateProcessLoading,
  } = useTextAdGenInfo();
  const location = useLocation();
  const searchParamFromState = getSearchParams(location.search);

  const { id } = searchParamFromState;
  const setAdGroupValues = (item) => {
    getSingleAdGroupsData({ adGroupId: item.id });
  };

  useEffect(() => {

    getGenerateProcess({
      userAdId: id,
    });
  }, [id]);

  useEffect(() => {
    isGenerationProcess &&
      getSingleAdGroupsData({
        adGroupId: isGenerationProcess.adGroups[0].id,
      });
  }, [isGenerationProcess]);

  const renderAdGroup = () => {
    return (
      <AdGroupWrap>
        <AdGroupContainer>
          {isGenerationProcess.adGroups.map((item, index) => {
            const isActive =
              adGroupItem && adGroupItem.adGroupRow.id == item.id;
            return (
              <NavAdDiv onClick={() => setAdGroupValues(item)}>
                <AdGroupValue isActive={isActive}>{`Ad Group ${
                  index + 1
                }`}</AdGroupValue>
                <HighlightLine isActive={isActive} />
              </NavAdDiv>
            );
          })}
        </AdGroupContainer>
      </AdGroupWrap>
    );
  };

  return isGenerationProcess ? (
    <ResultWrapper>
      <ResultContainer>
        {renderAdGroup()}
        <AdGroupInnerContent />
      </ResultContainer>
      <PublishAdInnerContent />
    </ResultWrapper>
  ) : (
    <div>Loading</div>
  );
};

export default TextAdGenerator;
