import styled from "styled-components";
import AdEditBgImage from "../../../../../../../../media/Dashboard/AdEditBg.svg"

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
`;

export const TemplateContainer = styled.div`
    width: calc(100% - 480px);
    background-image: url("../../../../../../../../media/Dashboard/AdEditBg.svg");
`;

export const TemplateWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 80%;
        max-width: 500px;
    }
`;

export const EditContainer = styled.div`
    width: 480px;
    display: flex;
    background: #FFF;
`;

export const NavigationComponent = styled.div`
    width: 80px;
    padding: 16px 8px;
    display: flex;
    gap: 12px;
    flex-direction: column;
`;

export const NavElementContainer = styled.div`
    height: 64px;
    width: 64px;
    display: flex;
    border-radius: 4px;
    border: solid 1px #F0F0F0;
    background: ${props => props.isActive ? "#1C1C1C": "#FDFDFD"};
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    gap: 8px;
    p{
        font-size: 14px;
        font-weight: 400;
        color: ${props => props.isActive ? "#FFF": "#1C1C1C"};
    }
    img{
        filter: ${props => props.isActive ? "invert(1)": "none"};
    }
`;

export const Divider = styled.div`
    height: 1px;
    background: #E8E8E8;
    margin: 2px 0px;
`;

export const EditComponent = styled.div`
    padding: 24px 16px;
    border-left: solid 1px #F0F0F0;
    width: 100%;
`;