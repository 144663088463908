import React, {useState} from "react";
import { Wrapper, InputElement } from "./index.styled"

const Caption = () => {
    const [inputValue, setInputValue] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a venenatis ligula. Sed id lacus lobortis, varius libero vitae, tristique justo.")
    return <Wrapper>
        <h2>Caption</h2>
        <InputElement onChange={(e)=> setInputValue(e.target.value)} value={inputValue}/>
    </Wrapper>
}

export default Caption