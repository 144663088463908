import React, { useState, useEffect } from "react";
import { Wrapper, HeaderContainer, HeaderLeft, HeaderRight, ContentContainer, GeneratingUi, ResultsContainer, CardsRow, AdCard, CardRowHeader, TypeOfAd, CardRowContent, TemplateDetailsContainer, TemplateDetailsWrapper, TemplateDetailsHeader, TemplateDetailsContent, EditTemplateButton } from "./index.styled"
import BackArrow from "../../../../../../../../media/Dashboard/backarrow.svg"
import AiLoader from "../../../../../../../../media/Dashboard/AiLoader.webp"
import DummyAdImage from "../../../../../../../../media/Dashboard/dummy-generated-image-ad.svg"
import CloseImage from "../../../../../../../../media/Dashboard/CloseDark.svg"
import { useNavigate } from "react-router-dom";

const tempRows = [{
    name: "Variation",
    adType: "Stock Image",
    ads: [
        {
            imgUrl: DummyAdImage,
            id: 0
        },
        {
            imgUrl: DummyAdImage,
            id: 1
        },
        {
            imgUrl: DummyAdImage,
            id: 2
        }
    ]
}
]

const Results = () => {
    const [resultsData, setResultsData] = useState(tempRows)
    const [selectedTemplate, setSelectedTemplate] = useState({})
    
    // useEffect(() => {
    //     setInterval(() => {
    //         setResultsData([...tempRows, ...resultsData])
    //     }, 2000);
    // }, [])

    const navigate = useNavigate()

    return <Wrapper>
        {selectedTemplate.imgUrl && <TemplateDetailsContainer>
            <TemplateDetailsWrapper>
                <TemplateDetailsHeader>
                    <h2>Details</h2>
                    <img src={CloseImage} onClick={()=>setSelectedTemplate({})}/>
                </TemplateDetailsHeader>
                <TemplateDetailsContent>
                    <img src={selectedTemplate.imgUrl}/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a venenatis ligula. Sed id lacus lobortis, varius libero vitae, tristique justo. Cras mattis ante congue, vehicula ante laoreet, hendrerit enim. Donec ac nunc at leo venenatis vestibulum. Morbi varius, libero sit amet blandit viverra, justo ex tempus eros, ut dignissim ex ligula id dui. Aliquam neque sem, sollicitudin et eleifend eu, feugiat a arcu. Sed in lobortis velit. Donec viverra, nisl sed dictum tincidunt, sapien eros laoreet erat, et maximus mi tortor nec justo. Nullam sodales neque et convallis posuere. Nam lobortis, nisl a sod</p>
                    <EditTemplateButton onClick={()=>navigate(`/adgenerator-v3/image-ad/result?id=${selectedTemplate.id}`)}>
                        Edit
                    </EditTemplateButton>
                </TemplateDetailsContent>
            </TemplateDetailsWrapper>
        </TemplateDetailsContainer>}
        <HeaderContainer>
            <HeaderLeft>
                <img src={BackArrow} /> <p>Results</p>
            </HeaderLeft>
            <HeaderRight>
                <span>Not satisfied with the design?</span>
                <button>
                    Generate Now
                </button>
            </HeaderRight>
        </HeaderContainer>
        <ContentContainer>
            <GeneratingUi>
                <img src={AiLoader} />
                <p>Generating..</p>
            </GeneratingUi>
            <ResultsContainer>
                {
                    resultsData.map((adRow) => {
                        return <CardsRow>
                            <CardRowHeader>
                                <p>{adRow.name}</p>
                                <TypeOfAd>
                                    {adRow.adType}
                                </TypeOfAd>
                            </CardRowHeader>
                            <CardRowContent>
                                {adRow.ads.map((ad) => {
                                    return <AdCard onClick={()=>setSelectedTemplate(ad)}>
                                        <img src={ad.imgUrl} />
                                    </AdCard>
                                })}
                            </CardRowContent>
                        </CardsRow>
                    })
                }
            </ResultsContainer>
        </ContentContainer>
    </Wrapper>
}

export default Results