import React, {useState} from "react";
import { Wrapper, HeadingDataContainer } from "./index.styled"

const Texts = () => {
    const [textData, setTextData] = useState([
        {
            name: "Heading 1",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque a venenatis ligula. Sed id lacus lobortis, varius libero vitae, tristique justo."
        },
        {
            name: "Heading 2",
            content: "Lorem ipsum dolor sit amet."
        },
        {
            name: "Heading 3",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        }
    ])

    const OnHeadngChange = (e, headingData) => {
        const updatedContent = e.target.value;

        setTextData(prevTextData =>
            prevTextData.map(item =>
                item.name === headingData.name
                    ? { ...item, content: updatedContent }
                    : item
            )
        );
    };


    return <Wrapper>
        {
            textData.map((headingData)=>{
                return <HeadingDataContainer>
                    <label>
                        {headingData.name}
                    </label>
                    <textarea onChange={(e)=>OnHeadngChange(e,headingData)} value={headingData.content}/>
                </HeadingDataContainer>
            })
        }
    </Wrapper>
}

export default Texts