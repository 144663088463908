import React from "react";
import {
  KeywordListWrap,
  SeedKey,
  SeedKeywordContainer,
  SeedKeywordDiv,
  SeedKeywordInput,
  SeedKeywordWrap,
} from "./index.styled";
import closeIcon from "../../../../../../../../../../../../media/Dashboard/closeIcon.svg";
import { useTextAdGenerator } from "../../../../../../../../context/textAdGeneration";
import {useAdGenerator} from "../../../../../../../../../context/adGeneration"

const SeedKeyword = () => {
  const {
    textAdGeneratorState,
    textAdGeneratorDispatch,
  } = useTextAdGenerator();
  const { adGeneratorState: {isAdDetails}, adGeneratorDispatch } = useAdGenerator()

  const seedKeywordfunction = (e) => {
    const seedValue = e.target.value;
    if (seedValue.trim() != "" && e.key === "Enter") {
      const updatedKeywordList = [...isAdDetails?.adDetails.seedKeywords];
      updatedKeywordList.push(e.target.value);
      const updatedAdGroupItem = {
        ...isAdDetails,
        adDetails: {
          ...isAdDetails.adDetails,
          seedKeywords: updatedKeywordList,
        },
      };
      adGeneratorDispatch({
        type: "SET_AD_DETAILS",
        value: updatedAdGroupItem,
      });
      e.target.value = "";
    }
  };

  const removeKeyList = (index) => {
    const updatedSeedList = isAdDetails?.adDetails.seedKeywords.filter(
      (_, i) => i !== index
    );
    const updatedSeedKey = {
      ...isAdDetails,
      adDetails: {
        ...isAdDetails.adDetails,
        seedKeywords: updatedSeedList,
      },
    };

    adGeneratorDispatch({
      type: "SET_AD_DETAILS",
      value: updatedSeedKey,
    });
  }

  return (
    <SeedKeywordWrap>
      <SeedKeywordContainer>
        <SeedKeywordDiv>
          <SeedKeywordInput
            placeholder="Type or Add keywords from the left panel"
            onKeyDown={(e) => seedKeywordfunction(e)}
          />
          <KeywordListWrap>
            {isAdDetails &&
              isAdDetails.adDetails.seedKeywords.map((item, index) => {
                return (
                  <SeedKey colorProp={index}>
                    {item}{" "}
                    <img src={closeIcon} onClick={() => removeKeyList(index)} />
                  </SeedKey>
                );
              })}
          </KeywordListWrap>
        </SeedKeywordDiv>
      </SeedKeywordContainer>
    </SeedKeywordWrap>
  );
};

export default SeedKeyword;
